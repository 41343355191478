import SamaritanFetch from './httpClient';

type ReferralParams =
    | { homelessID: number; orgID?: never; userID?: never }
    | { orgID: number; userID?: number; homelessID?: never }
    | { userID: number; orgID?: number; homelessID?: never };

const buildQueryParams = (
    params: Record<string, string | number | undefined>
): string => {
    const queryParts: string[] = [];
    for (const [key, value] of Object.entries(params)) {
        if (value !== undefined) {
            queryParts.push(`${key}[]=${value}`);
        }
    }
    return queryParts.join('&');
};

const createRequest = (
    requests: { request: Promise<Response>; queryType: string }[],
    queryParams: Record<string, string>,
    queryType: string
) => {
    const queryString = buildQueryParams(queryParams);
    requests.push({
        request: SamaritanFetch.v3.get(
            `/ctp_caseload_referrals?${queryString}`,
            {
                headers: { 'Content-Type': 'application/json' }
            }
        ),
        queryType
    });
};

export const fetchReferrals = async (
    params: ReferralParams
): Promise<
    { success: boolean; response: Response | null; queryType: string }[]
> => {
    const requests: { request: Promise<Response>; queryType: string }[] = [];

    // Handle homelessID case
    if (params.homelessID) {
        createRequest(
            requests,
            {
                'ctp_caseload_referrals[homeless_id]':
                    params.homelessID.toString()
            },
            'homelessID'
        );
    }

    // Handle orgID case, but only if userID is not present
    if (params.orgID && !params.userID) {
        createRequest(
            requests,
            {
                'ctp_caseload_referrals[organization_id]':
                    params.orgID.toString()
            },
            'orgID'
        );
    }

    // Handle userID case, but only if orgID is not present
    if (params.userID && !params.orgID) {
        createRequest(
            requests,
            { 'ctp_caseload_referrals[referrer_id]': params.userID.toString() },
            'userID'
        );
    }

    // Handle both userID and orgID cases separately
    if (params.userID && params.orgID) {
        createRequest(
            requests,
            { 'ctp_caseload_referrals[referee_id]': params.userID.toString() },
            'userID'
        );
        createRequest(
            requests,
            {
                'ctp_caseload_referrals[organization_id]':
                    params.orgID.toString()
            },
            'orgID'
        );
    }

    // Execute all requests and handle responses
    const results = await Promise.all(
        requests.map(async ({ request, queryType }) => {
            try {
                const response = await request;
                return { success: response.ok, response, queryType };
            } catch (error) {
                console.error(`Failed API call for ${queryType}:`, error);
                return { success: false, response: null, queryType };
            }
        })
    );

    return results;
};

export const fetchIncomingUserReferrals = (userID: number, orgID?: number) => {
    return fetchReferrals({ userID, orgID });
};

export const fetchMemberReferrals = (homelessID: number) => {
    return fetchReferrals({ homelessID });
};

export const fetchOutgoingUserReferrals = (userID: number) => {
    return fetchReferrals({ userID });
};

export const fetchUniqueMemberInfo = (memberIDs: number[]) => {
    const queryString = memberIDs
        .map((id) => `samaritan_members[id][]=${id}`)
        .join('&');

    return SamaritanFetch.v3.get(
        `samaritan_members?template=micro&${queryString}`,
        {
            headers: {
                'content-type': 'application/json'
            }
        }
    );
};

// export const createMemberCaseload = (memberID: number) => {
//     const memberCaseload = {
//         ctp_caseload: {
//             homeless_id: memberID,
//             status: 'active'
//         }
//     };

//     return SamaritanFetch.v3.post('ctp_caseloads', {
//         headers: {
//             'content-type': 'application/json'
//         },
//         body: JSON.stringify(memberCaseload)
//     });
// };

// export const fetchUserCaseloads = () => {
//     return SamaritanFetch.v3('ctp_caseloads', {
//         method: 'GET'
//     });
// };

// export const removeMemberFromMyMembers = (caseloadID: number) => {
//     const cancelledStatus = {
//         ctp_caseload: {
//             status: 'cancelled'
//         }
//     };

//     return SamaritanFetch.v3.put(`ctp_caseloads/${caseloadID}`, {
//         headers: {
//             'content-type': 'application/json'
//         },
//         body: JSON.stringify(cancelledStatus)
//     });
// };

// export const saveMemberToMyMembers = (caseloadID: number) => {
//     const activeStatus = {
//         ctp_caseload: {
//             status: 'active'
//         }
//     };

//     return SamaritanFetch.v3.put(`ctp_caseloads/${caseloadID}`, {
//         headers: {
//             'content-type': 'application/json'
//         },
//         body: JSON.stringify(activeStatus)
//     });
// };
