import { connect } from 'react-redux';

import HoldersList from '../components/holders_list';

import actions from '../redux/actions';

// state is object that holds reducers from combineReducers and hence the state in them
function mapStateToProps(state: any) {
    const {
        deploymentOrgs,
        deploymentUsers,
        isFetchingDeploymentOrgs,
        isFetchingDeploymentUsers
    } = state.deployments;
    const { historyFilters, isFetchingHistoryFilters } = state.homeless;
    const { caseloads, homelesses, isFetchingCaseloads, isFetchingHomelesses } =
        state.homelesses;
    const { layoutTitles } = state.samaritan;
    const { user } = state.user;

    return {
        caseloads,
        deploymentOrgs,
        deploymentUsers,
        historyFilters,
        homelesses,
        layoutTitles,
        isFetchingCaseloads,
        isFetchingDeploymentOrgs,
        isFetchingDeploymentUsers,
        isFetchingHistoryFilters,
        isFetchingHomelesses,
        user
    };
}

const mapDispatchToProps = {
    fetchDeploymentOrgsRequest: actions.fetchDeploymentOrgsRequest,
    getDeploymentUsers: actions.fetchDeploymentUsersRequest,
    getCaseloads: actions.getCaseloads,
    getHistoryFilters: actions.getHistoryFilters,
    getHomelesses: actions.getHomelesses,
    removeMemberFromMyMembers: actions.removeMemberFromMyMembers,
    resetCatchUpItemStatus: actions.resetCatchUpItemStatus,
    resetFullMemberPayments: actions.resetFullMemberPayments,
    resetMemberReferrals: actions.resetMemberReferrals,
    resetMessageInfo: actions.resetMessageInfo,
    resetHomelessInfo: actions.resetHomelessInfo,
    resetTouchpointAnswers: actions.resetTouchpointAnswers,
    resetUnenrollmentState: actions.resetUnenrollmentState,
    saveMemberToMyMembers: actions.saveMemberToMyMembers,
    setLayoutTitles: actions.setLayoutTitles
};

export default connect(mapStateToProps, mapDispatchToProps)(HoldersList);
