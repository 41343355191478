import Lottie from 'lottie-react';

import CheckMark from 'assets/lottie/lottie_check_samaritan.json';
import Loading from 'assets/lottie/samaritan_loading_lottie.json';

import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        borderRadius: '16px',
        color: '#1FAD64',
        fontSize: '20px',
        fontWeight: 800,
        height: '100%',
        width: '100%'
    }
});

type OverlayProps = {
    loading?: boolean;
    text: string;
    timeout?: number;
};

export const Overlay = ({ loading, text, timeout = 500 }: OverlayProps) => {
    const classes = useStyles();

    return (
        <Fade in={true} timeout={timeout}>
            <div className={classes.overlay}>
                {!loading ? (
                    <Lottie
                        animationData={CheckMark}
                        loop={false}
                        style={{ height: '40px', width: '40px' }}
                    />
                ) : (
                    <Lottie
                        animationData={Loading}
                        loop={true}
                        style={{ height: '40px', width: '40px' }}
                    />
                )}
                <span style={loading ? { color: '#FFFFFF' } : {}}>{text}</span>
            </div>
        </Fade>
    );
};

export default Overlay;
