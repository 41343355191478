import CONSTANTS from '../constants/constants';

import { Referral } from 'types';

import * as api from 'api/referrals';

import { handleSamaritanApiError } from './error';

type ReferralParams =
    | { homelessID: number; orgID?: never; userID?: never }
    | { orgID: number; userID?: number; homelessID?: never }
    | { userID: number; orgID?: number; homelessID?: never };

export function acceptReferral(referralID: number) {
    return {
        type: CONSTANTS.ACCEPT_REFERRAL,
        payload: referralID
    };
}

function fetchIncomingReferrals() {
    return {
        type: CONSTANTS.FETCH_INCOMING_REFERRALS
    };
}

function fetchMemberReferrals() {
    return {
        type: CONSTANTS.FETCH_MEMBER_REFERRALS
    };
}

function fetchOutgoingReferrals() {
    return {
        type: CONSTANTS.FETCH_OUTGOING_REFERRALS
    };
}

function fetchUniqueMemberInfo() {
    return {
        type: CONSTANTS.FETCH_UNIQUE_MEMBER_INFO
    };
}

export function rejectReferral(referralID: number) {
    return {
        type: CONSTANTS.REJECT_REFERRAL,
        payload: referralID
    };
}

export function resetMemberReferrals() {
    return {
        type: CONSTANTS.RESET_MEMBER_REFERRALS
    };
}

export function resetUserReferrals() {
    return {
        type: CONSTANTS.RESET_USER_REFERRALS
    };
}

function setIncomingReferrals(list: Referral[]) {
    return {
        type: CONSTANTS.SET_INCOMING_REFERRALS,
        payload: list
    };
}

function setOutgoingReferrals(list: Referral[]) {
    return {
        type: CONSTANTS.SET_OUTGOING_REFERRALS,
        payload: list
    };
}

function setMemberReferrals(list: Referral[]) {
    return {
        type: CONSTANTS.SET_MEMBER_REFERRALS,
        payload: list
    };
}

function setUniqueMemberInfo(info: any) {
    return {
        type: CONSTANTS.SET_UNIQUE_MEMBER_INFO,
        payload: info
    };
}

export function updateMemberReferrals(referral: Referral) {
    return {
        type: CONSTANTS.UPDATE_MEMBER_REFERRALS,
        payload: referral
    };
}

export const fetchMemberReferralsRequest = (homelessID: number) => {
    return async (dispatch: Function) => {
        try {
            dispatch(fetchMemberReferrals());

            const responses = await api.fetchReferrals({ homelessID });

            const homelessResponse = responses.find(
                (res) => res.queryType === 'homelessID'
            );

            if (homelessResponse?.success && homelessResponse.response) {
                const resJson = await homelessResponse.response.json();

                dispatch(setMemberReferrals(resJson.ctp_caseload_referrals));
            } else {
                console.error(
                    'Failed to fetch member referrals:',
                    homelessResponse?.response?.statusText || 'Unknown error'
                );
            }
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
};

export const fetchUserReferralsRequest = (userID: number, orgID?: number) => {
    return async (dispatch: Function) => {
        try {
            if (!orgID) {
                dispatch(fetchOutgoingReferrals());
            } else {
                dispatch(fetchIncomingReferrals());
            }

            const params: ReferralParams = orgID
                ? { orgID, userID }
                : { userID };

            const responses = await api.fetchReferrals(params);
            const responseQueryTypes = responses.map((res) => res.queryType);

            for (const { success, response, queryType } of responses) {
                if (success && response) {
                    const resJson = await response.json();

                    if (['userID', 'orgID'].includes(queryType)) {
                        const homelessIDs = Array.from(
                            new Set(
                                resJson.ctp_caseload_referrals.map(
                                    (referral: Referral) => referral.homeless_id
                                )
                            )
                        );

                        const dispatchReferrals =
                            responseQueryTypes.length === 1
                                ? setOutgoingReferrals
                                : setIncomingReferrals;

                        const referrals = resJson.ctp_caseload_referrals;
                        let filteredReferrals = referrals;

                        if (responseQueryTypes.length === 2) {
                            filteredReferrals = filteredReferrals.filter(
                                (referral: Referral) => {
                                    return (
                                        referral.organization?.referee_ids.includes(
                                            userID
                                        ) || referral.referee_id === userID
                                    );
                                }
                            );
                        }

                        dispatch(dispatchReferrals(filteredReferrals));

                        dispatch(
                            fetchUniqueMemberInfoRequest(
                                homelessIDs as number[]
                            )
                        );
                    } else {
                        console.warn(
                            `Unexpected query type "${queryType}" in API response.`
                        );
                    }
                } else {
                    console.error(
                        `Failed to fetch referrals for ${queryType}.`
                    );
                }
            }
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
};

export const fetchUniqueMemberInfoRequest = (homelessIDs: number[]) => {
    return async (dispatch: Function) => {
        try {
            dispatch(fetchUniqueMemberInfo());

            const response = await api.fetchUniqueMemberInfo(homelessIDs);

            if (response && response.ok) {
                const resJson = await response.json();

                dispatch(setUniqueMemberInfo(resJson.samaritan_members));
            } else {
                console.error(
                    'Failed to fetch unique member info:',
                    response?.statusText || 'Unknown error'
                );
            }
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
};
