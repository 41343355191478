import { useCallback, useEffect, useState } from 'react';

import { RemoteCopy } from 'types';

import { fetchRemoteCopy } from 'api/remote_copy';

export default function useRemoteCopy(copyType: string) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const [copy, setCopy] = useState<null | RemoteCopy>(null);
    const [error, setError] = useState(null);

    const fetch = useCallback(() => {
        fetchRemoteCopy(copyType)
            .then((res: { json: () => any }) => res.json())
            .then((data: { remote_copy: RemoteCopy }) => {
                setCopy(data.remote_copy);
                setIsLoaded(true);
                setIsError(false);
                setIsLoading(false);
                setError(null);
            })
            .catch((e: any) => {
                setCopy(null);
                setIsLoading(true);
                setIsError(true);
                setIsLoaded(false);
                setError(e);
            });
    }, [copyType]);

    useEffect(() => {
        setCopy(null);
        setIsLoading(true);
        setIsError(false);
        setIsLoaded(false);
        setError(null);
        fetch();
    }, [fetch]);

    return {
        remoteCopy: copy,
        isLoading,
        isLoaded,
        isError,
        error,
        refetch: fetch
    };
}
