import SamaritanFetch from './httpClient';

export const checkTargetListMember = async ({
    dob,
    memberID,
    memberFirstName,
    memberLastName
}: {
    dob?: string;
    memberID?: string;
    memberFirstName?: string;
    memberLastName?: string;
}) => {
    const params = new URLSearchParams();

    if (memberID) {
        params.append('samaritan_member[pii_member_id]', memberID.toString());
    } else if (dob && memberFirstName && memberLastName) {
        params.append('samaritan_member[date_of_birth]', dob);
        params.append('samaritan_member[pii_first_name]', memberFirstName);
        params.append('samaritan_member[pii_last_name]', memberLastName);
    } else {
        throw new Error(
            'Invalid parameters: Provide either memberID or dob + firstName + lastName.'
        );
    }

    try {
        const response = await SamaritanFetch.v3.get(
            `/samaritan_members/search?${params.toString()}`
        );

        if (!response.ok) {
            throw new Error('Failed to fetch target list');
        }

        const data = await response.json();
        const targetListMembers = data.samaritan_members.filter(
            (member: any) => member.profile_stage === 'TARGET_LIST'
        );

        return targetListMembers;
    } catch (error) {
        console.error('Error in checkTargetListMember:', error);
        throw error;
    }
};

export const fetchTemplateActions = (org_id: number) =>
    SamaritanFetch.v3(
        `/action_step_templates?action_step_templates[organization_id]=${org_id}`
    );

export const fetchTemplateBeacons = () =>
    SamaritanFetch.v2('/homelesses', {
        query: {
            profile_stage: ['TEMPLATE']
        }
    });

export const submitIntake = (data: any, _beaconId: string) => {
    return SamaritanFetch.v2(`/homelesses`, {
        body: data,
        method: 'POST'
    });
};
