import ReactMarkdown from 'react-markdown';

import ChartIcon from 'assets/icon-chart.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    chartIcon: {
        width: '16px',
        height: '16px'
    },
    date: {
        fontWeight: '800'
    },
    debitIcon: {
        padding: '0 4px'
    },
    memberHistoryBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    memberHistoryItem: {
        display: 'flex',
        flexDirection: 'column',
        color: '#1F2152'
    },
    iconAction: {
        color: '#FFFFFF',
        backgroundColor: '#062D7A'
    },
    iconMinus: {
        color: '#A80A2D',
        backgroundColor: '#FEE7EC'
    },
    iconPlus: {
        color: '#1B9757',
        backgroundColor: '#E9FBF2'
    },
    iconStatus: {
        color: '#062D7A',
        backgroundColor: '#E7EFFE'
    },
    itemAmount: {
        fontSize: '20px',
        fontWeight: '800'
    },
    itemDescription: {
        color: '#5A6A81',
        '& p': {
            margin: 0
        }
    },
    itemDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },
    itemDivider: {
        borderLeft: '2px solid #E5E5E5',
        height: '48px',
        margin: '4px 0 4px 24px'
    },
    itemIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        width: '40px',
        height: '40px',
        marginLeft: '6px',
        borderRadius: '50%'
    },
    itemLeft: {
        display: 'flex',
        alignItems: 'center',
        gap: '40px'
    },
    itemType: {
        display: 'flex',
        fontSize: '20px',
        fontWeight: '800'
    }
}));

type MemberHistoryItemProps = {
    history: any[];
    item: any;
    totalIndex: number;
};

type TypeMappings = {
    [key: string]: {
        icon: string;
        iconClass: string;
        title: JSX.Element | string;
        amount: string;
        description: JSX.Element | string;
    };
};

const MemberHistoryItem = ({
    history,
    item,
    totalIndex
}: MemberHistoryItemProps) => {
    const classes = useStyles();

    const { data, type } = item;
    // no way to determine if a replenishment from a closed card is not a donation

    const typeMappings: TypeMappings = {
        action_step_bonus_earned: {
            icon: 'far fa-plus',
            iconClass: classes.iconPlus,
            title: 'Action Step Bonus Earned',
            amount: `+ $${data.amount}`,
            description: `New Member Balance: $${data.new_member_balance}`
        },
        action_step_created: {
            icon: 'fas fa-clipboard-list',
            iconClass: classes.iconAction,
            title: 'New Action Step Created',
            amount: '',
            description: `${data.description}`
        },
        action_step_completed: {
            icon: 'fas fa-clipboard-list',
            iconClass: classes.iconAction,
            title: 'Action Step Complete',
            amount: '',
            description: `${data.description}`
        },
        debit_transfer: {
            icon: 'far fa-minus',
            iconClass: classes.iconMinus,
            title: (
                <span>
                    Transfer to Debit Card{' '}
                    <i className={`far fa-credit-card ${classes.debitIcon}`} />{' '}
                    {data.last4}
                </span>
            ),
            amount: `$${data.amount}`,
            description: `New Member Balance: $${data.new_member_balance}`
        },
        general_donation: {
            icon: 'far fa-plus',
            iconClass: classes.iconPlus,
            title: 'Donation from Support Network',
            amount: `$${data.amount}`,
            description: `New Member Balance: $${data.new_member_balance}`
        },
        give_card_assign: {
            icon: 'far fa-credit-card',
            iconClass: classes.iconStatus,
            title: 'GiveCard Assign',
            amount: '',
            description: (
                <span>
                    New Card{' '}
                    <i className={`far fa-credit-card ${classes.debitIcon}`} />{' '}
                    {data.last4}
                </span>
            )
        },
        give_card_replacement: {
            icon: 'far fa-credit-card',
            iconClass: classes.iconStatus,
            title: 'GiveCard Replacement',
            amount: '',
            description: (
                <span>
                    New Card{' '}
                    <i className={`far fa-credit-card ${classes.debitIcon}`} />{' '}
                    {data.last4}
                </span>
            )
        },
        give_card_transfer: {
            icon: 'far fa-minus',
            iconClass: classes.iconMinus,
            title: (
                <span>
                    Transfer to Debit Card{' '}
                    <i className={`far fa-credit-card ${classes.debitIcon}`} />{' '}
                    {data.last4}
                </span>
            ),
            amount: `$${data.amount}`,
            description: `New Member Balance: $${data.new_member_balance}`
        },
        need_created: {
            icon: 'fas fa-hands-heart',
            iconClass: classes.iconAction,
            title: 'New Need Shared to Support Network',
            amount: '',
            description: data.amount
                ? `${data.description} for $${data.amount}`
                : `${data.description}`
        },
        need_donation: {
            icon: 'far fa-plus',
            iconClass: classes.iconPlus,
            title: `Donation to Fund "${data.description}"`,
            amount: `$${data.amount}`,
            description: `New Member Balance: $${data.new_member_balance}`
        },
        need_funded: {
            icon: 'fas fa-hands-heart',
            iconClass: classes.iconAction,
            title: 'Need Fully Funded',
            amount: '',
            description: data.amount
                ? `${data.description} for $${data.amount}`
                : `${data.description}`
        },
        new_status_captured: {
            icon: 'far fa-chart',
            iconClass: classes.iconStatus,
            title: `New ${data.qol_name} Status Saved`,
            amount: '',
            description: `${data.description}`
        },
        pex_card_assign: {
            icon: 'far fa-credit-card',
            iconClass: classes.iconStatus,
            title: 'PEX Card Assign',
            amount: '',
            description: (
                <span>
                    New Card{' '}
                    <i className={`far fa-credit-card ${classes.debitIcon}`} />{' '}
                    {data.last4}
                </span>
            )
        },
        pex_card_replacement: {
            icon: 'far fa-credit-card',
            iconClass: classes.iconStatus,
            title: 'Debit Card Replacement',
            amount: '',
            description: (
                <span>
                    New Card{' '}
                    <i className={`far fa-credit-card ${classes.debitIcon}`} />{' '}
                    {data.last4}
                </span>
            )
        },
        redemption: {
            icon: 'far fa-minus',
            iconClass: classes.iconMinus,
            title: 'Purchase Request Submitted',
            amount: `- $${data.amount}`,
            description: `New Member Balance: $${data.new_member_balance}`
        },
        replenish: {
            icon: 'far fa-plus',
            iconClass: classes.iconPlus,
            title: 'Replenishing Funds',
            amount: `+ $${data.amount}`,
            description: `New Member Balance: $${data.new_member_balance}`
        }
    };

    const renderAmount = (type: string) => typeMappings[type]?.amount || '';
    const renderDescription = (type: string) =>
        typeMappings[type]?.description || 'Unknown';
    const renderIcon = (type: string) => typeMappings[type]?.icon || '';
    const renderIconClass = (type: string) =>
        typeMappings[type]?.iconClass || '';
    const renderType = (type: string) => typeMappings[type]?.title || 'Unknown';

    return (
        <div className={classes.memberHistoryItem}>
            <div className={classes.memberHistoryBody}>
                <div className={classes.itemLeft}>
                    <div
                        className={`${classes.itemIcon} ${renderIconClass(
                            type
                        )}`}
                    >
                        {type === 'new_status_captured' ? (
                            <img
                                className={classes.chartIcon}
                                src={ChartIcon}
                                alt="chart icon"
                            />
                        ) : (
                            <i className={renderIcon(type)} />
                        )}
                    </div>
                    <div className={classes.itemDetails}>
                        <span className={classes.itemType}>
                            {renderType(type)}
                        </span>
                        <span className={classes.itemDescription}>
                            {type === 'action_step_created' ? (
                                <ReactMarkdown
                                    children={renderDescription(type) as string}
                                />
                            ) : (
                                <>{renderDescription(type)}</>
                            )}
                        </span>
                    </div>
                </div>
                <span className={classes.itemAmount}>{renderAmount(type)}</span>
            </div>
            {totalIndex !== history.length - 1 && (
                <div className={classes.itemDivider} />
            )}
        </div>
    );
};

export default MemberHistoryItem;
