import { Fragment } from 'react';
import Lottie from 'lottie-react';

import { ActionStep, RemoteCopy } from 'types';

import ActionStepCard from './ActionStepCard';
import ActionStepForm from './ActionStepForm';

import Sparkle from 'assets/lottie/sparkle-effect.json';

import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    sparkleContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    }
}));

type ActionStepSummaryProps = {
    actionSuggestions: null | RemoteCopy;
    actionType: ActionStep[];
    editAction: number[];
    from: string;
    intakeGoals: ActionStep[];
    remainingBonusAmount: number;
    setDisplayNeedBtns?: (displayNeedBtns: boolean) => void;
    setEditAction: (editAction: number[]) => void;
    setGuide: (guide: boolean) => void;
    setIntakeGoals: (intakeGoals: ActionStep[]) => void;
    setShowForm: (showForm: boolean) => void;
    showForm: boolean;
};

const ActionStepSummary: React.FC<ActionStepSummaryProps> = ({
    actionSuggestions,
    actionType,
    editAction,
    from,
    intakeGoals,
    remainingBonusAmount,
    setDisplayNeedBtns,
    setEditAction,
    setGuide,
    setIntakeGoals,
    setShowForm,
    showForm
}) => {
    const classes = useStyles();

    const count = actionType ? actionType.length : 0;
    // do something here for custom step?
    const customStep = false;
    const lastIndex = count - 1;

    const editForm = (index: number) => {
        return {
            actionIndex: index,
            costInputState: intakeGoals[index].amount,
            customGoalInputState: intakeGoals[index].customGoals,
            dateInputState: intakeGoals[index].due_at,
            descriptionInput: true,
            descriptionInputState: intakeGoals[index].description,
            goalInputState: intakeGoals[index].goals,
            qolMeasureState: intakeGoals[index].qolMeasure
        };
    };

    const handleEditClick = (index: number) => {
        setShowForm(false);

        if (!editAction.includes(index)) {
            setEditAction([...editAction, index]);
        }
    };

    return (
        <>
            {actionType.map((action, index) => {
                const isLastItem = index === lastIndex;

                if (editAction.includes(index)) {
                    return (
                        <Fade
                            in={!showForm}
                            timeout={500}
                            key={`edit-fade-${index}`}
                        >
                            <div style={{ width: '100%' }}>
                                <ActionStepForm
                                    actionSuggestions={actionSuggestions}
                                    editAction={editAction}
                                    editForm={editForm(index)}
                                    from={from}
                                    intakeGoals={intakeGoals}
                                    onClick={() => setDisplayNeedBtns!(true)}
                                    remainingBonusAmount={remainingBonusAmount}
                                    setEditAction={setEditAction}
                                    setGuide={setGuide}
                                    setIntakeGoals={setIntakeGoals}
                                    setShowForm={setShowForm}
                                    showEdit={true}
                                    showForm={showForm}
                                />
                            </div>
                        </Fade>
                    );
                }

                const cardContent = (
                    <ActionStepCard
                        action={action}
                        actionType={actionType}
                        count={count}
                        customStep={customStep}
                        handleEditClick={handleEditClick}
                        index={index}
                        intakeGoals={intakeGoals}
                    />
                );

                return isLastItem && index !== 0 && index < 4 ? (
                    <Fade in={true} timeout={500} key={`fade-${index}`}>
                        <div className={classes.sparkleContainer}>
                            <Lottie
                                animationData={Sparkle}
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    height: '56px',
                                    right: '-8px',
                                    top: '16px',
                                    width: '56px'
                                }}
                            />
                            {cardContent}
                        </div>
                    </Fade>
                ) : (
                    <Fragment key={`fade-${index}`}>{cardContent}</Fragment>
                );
            })}
        </>
    );
};

export default ActionStepSummary;
