import { useRef, useState } from 'react';

import { CTAButton1 } from 'components/_shared/buttons';

import TextAreaAutosize from '@mui/material/TextareaAutosize';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    addInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '100%'
    },
    customInput: {
        width: '94%',
        padding: '16px',
        borderRadius: '16px',
        border: '1px solid #E3E3FA',
        fontSize: '16px',
        fontFamily: 'Manrope',
        fontWeight: 400,
        color: '#4F4F4F',
        resize: 'none',
        '&::placeholder': {
            color: '#BDBDBD'
        },
        '&:focus': {
            outline: 'none'
        }
    },
    customInputContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    subtitle: {
        color: '#1F2152',
        fontWeight: 500,
        fontSize: '14'
    }
}));

type AddInfoProps = {
    infoTitle: string;
    infoType: string;
    saveClick: () => void;
    setInfo: (info: string) => void;
};

const AddInfo: React.FC<AddInfoProps> = ({
    infoTitle,
    infoType,
    saveClick,
    setInfo
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const [input, setInput] = useState('');

    const handleSaveClick = () => {
        setInfo(input);
        saveClick();
    };

    return (
        <div className={classes.addInfoContainer}>
            <span className={classes.subtitle}>{infoTitle}</span>
            <TextAreaAutosize
                className={classes.customInput}
                maxRows={8}
                minRows={8}
                onChange={(e) => setInput(e.target.value)}
                placeholder={'Type your notes here...'}
                ref={inputRef}
                spellCheck={'false'}
                value={input}
            />
            <CTAButton1
                disabled={!input.length}
                onClick={handleSaveClick}
                style={{
                    fontSize: '16px'
                }}
            >
                Save {infoType.charAt(0).toUpperCase() + infoType.slice(1)}
            </CTAButton1>
        </div>
    );
};

export default AddInfo;
