import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import LoadingCircle from '../../../../_shared/LoadingCircle';

import { unenrollHomeless } from '../../../../../api/homeless';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        reenroll: {
            width: '100%'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            gap: '16px',
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            color: '#1F2512',
            padding: '32px'
        },
        title: {
            fontSize: '1.2rem',
            fontWeight: 700,
            margin: 'auto',
            paddingBottom: '16px'
        },
        subtitle: {
            fontSize: '1rem',
            fontWeight: 400,
            margin: 'auto',
            paddingBottom: '16px'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center'
        },
        button: {
            margin: 'auto',
            color: '#FFFFFF',
            background:
                'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',
            textTransform: 'none',
            padding: '10px 32px',
            borderRadius: '50px',
            fontSize: '16px',
            lineHeight: '32px',
            fontWeight: 700
        }
    })
);

type Props = {
    holder: {
        exited_internal_context: string;
        id: string;
        name: string;
    };
};

const Reenroll: React.FC<Props> = ({ holder }) => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const { id, name } = holder;

    const handleSubmitReenroll = async () => {
        const _formData = new FormData();

        setLoading(true);

        _formData.append('samaritan_member[profile_stage]', 'LIVE');

        try {
            const reenroll = await unenrollHomeless(Number(id), _formData);

            if (reenroll.status === 200) {
                setLoading(false);
                setSuccess(true);

                const fromState = {
                    from: 'reenroll'
                };

                setTimeout(() => {
                    history.push(`${match.url}`, fromState);
                    history.go(0);
                }, 2000);
            }
        } catch (error) {
            console.log(`error`, error);
            setLoading(false);
        }
    };

    return (
        <div className={classes.reenroll}>
            <div className={classes.body}>
                <span className={classes.title}>
                    {`Are you sure you want to re-enroll ${name}?`}
                </span>
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.button}
                        onClick={() => handleSubmitReenroll()}
                        disabled={isLoading || isSuccess}
                        style={
                            isSuccess
                                ? { background: '#7FE8B2', color: '#FFFFFF' }
                                : { color: '#FFFFFF' }
                        }
                    >
                        {isLoading ? (
                            <LoadingCircle />
                        ) : isSuccess ? (
                            'Success!'
                        ) : (
                            `Yes, Re-Enroll ${name}`
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Reenroll;
