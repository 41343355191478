import { TargetListMember } from 'types';

import TargetSearch from 'assets/target-search.png';
import { CTAButton3 } from 'components/_shared/buttons/';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: '16px',
        padding: '16px'
    },
    targetListContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        '& img': {
            height: 'auto',
            margin: '0 auto',
            width: '120px'
        }
    },
    memberInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px 0'
    },
    memberInfo: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    subtitle: {
        fontWeight: 400
    },
    title: {
        fontSize: '24px',
        fontWeight: 800
    }
});

type TargetListPopUpProps = {
    member: TargetListMember;
    onAccept: () => void;
    onReject: () => void;
};

const TargetListPopUp: React.FC<TargetListPopUpProps> = ({
    member,
    onAccept,
    onReject
}) => {
    const classes = useStyles();

    const { date_of_birth, pii_first_name, pii_last_name, pii_member_id } =
        member;

    return (
        <>
            <div className={classes.targetListContainer}>
                <img src={TargetSearch} alt="Target Search" />
                <div>
                    <div className={classes.heading}>
                        <span className={classes.title}>
                            We might have {pii_first_name} on file!
                        </span>
                        <span className={classes.subtitle}>
                            Does the information below match the new Member
                            being signed up? If so, we'll pre-fill any
                            information we have on file into their Membership!
                        </span>
                    </div>
                    <div className={classes.memberInfoContainer}>
                        <div className={classes.memberInfo}>
                            <span>Full Name</span>
                            <b>
                                {pii_first_name} {pii_last_name}
                            </b>
                        </div>
                        <div className={classes.memberInfo}>
                            <span>Date of Birth</span>
                            <b>{date_of_birth}</b>
                        </div>
                        <div className={classes.memberInfo}>
                            <span>Insurance ID #</span>
                            <b>{pii_member_id}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <CTAButton3 border invert onClick={onReject}>
                    No, Close
                </CTAButton3>
                <CTAButton3 onClick={onAccept}>Yes, That's Them</CTAButton3>
            </div>
        </>
    );
};

export default TargetListPopUp;
