import { combineReducers } from 'redux';

import * as constants from '../constants/constants';
import samaritan from './samaritan';
import catchUpItems from './catch_up_items';
import debitCard from './debit_card';
import determinantRanks from './determinant_ranks';
import deployments from './deployments';
import error from './error';
import goalActions from './goal_actions';
import goals from './goals';
import homeless from './homeless';
import homelesses from './homelesses';
import intake from './intake';
import messages from './messages';
import needs from './needs';
import payments from './payments';
import pex from './pex';
import referrals from './referrals';
import touchpointAnswers from './touchpoint_answers';
import touchpointQuestions from './touchpoint_questions';
import touchpoints from './touchpoints';
import redemption from './redemption';
import user from './user';
import unenrollment from './unenrollment';

const appReducer = combineReducers({
    samaritan,
    error,
    catchUpItems,
    debitCard,
    determinantRanks,
    deployments,
    goalActions,
    goals,
    homeless,
    homelesses,
    intake,
    messages,
    needs,
    payments,
    pex,
    referrals,
    touchpointAnswers,
    touchpointQuestions,
    touchpoints,
    redemption,
    user,
    unenrollment
});

// from: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state: any, action: any) => {
    if (action.type === constants.UNSET_USER) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
