import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { Caseload, Referral, UniqueMemberInfo, User } from 'types';

import EmptyReferrals from 'assets/empty_referral_list.png';
import Nav from 'components/_shared/NavSelector';
import ReferralCard from './components/ReferralCard';
import { CTAButton3 } from 'components/_shared/buttons';

import { isObjectEmpty } from 'util/index';

import { Box, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        margin: '24px auto 40px auto',
        maxWidth: '640px',
        width: '90%',
        '@media (max-width: 768px)': {
            maxWidth: '100%'
        }
    },
    empty: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        margin: '48px auto',
        '& img': {
            maxWidth: '50%',
            height: 'auto'
        }
    },
    referralsBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '95%'
    },
    referralsPage: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '2rem',
        height: '100vh',
        overflow: 'auto'
    },
    referralsTitle: {
        paddingTop: '10px'
    },
    refreshContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        padding: '24px 0',
        '& span': {
            fontSize: '20px',
            fontWeight: 800,
            textAlign: 'center'
        }
    }
}));

type ReferralsTabProps = {
    acceptReferral: (referralID: number) => void;
    caseloads: Caseload[];
    fetchCaseloads: (userID: number) => void;
    fetchReferrals: (userID: number, orgID?: number) => void;
    incomingReferrals: Referral[] | null;
    isFetchingCaseloads: boolean;
    isFetchingIncomingReferrals: boolean;
    isFetchingOutgoingReferrals: boolean;
    outgoingReferrals: Referral[] | null;
    uniqueMemberInfo: UniqueMemberInfo[] | null;
    rejectReferral: (referralID: number) => void;
    resetUserReferrals: () => void;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (
        caseloadID: number,
        memberID: number,
        accepted?: boolean
    ) => void;
    setLayoutTitles: (title: string, subtitle: string) => void;
    user: User;
};

const ReferralsTab: React.FC<ReferralsTabProps> = ({
    acceptReferral,
    caseloads,
    fetchCaseloads,
    fetchReferrals,
    incomingReferrals,
    isFetchingCaseloads,
    isFetchingIncomingReferrals,
    isFetchingOutgoingReferrals,
    outgoingReferrals,
    uniqueMemberInfo,
    rejectReferral,
    resetUserReferrals,
    removeMemberFromMyMembers,
    saveMemberToMyMembers,
    setLayoutTitles,
    user
}) => {
    const classes = useStyles();
    const location = useLocation();

    const [active, setActive] = useState('Outgoing Referrals');

    const direction = active === 'Outgoing Referrals' ? 'outgoing' : 'incoming';

    const isLoading = useMemo(() => {
        return (
            !incomingReferrals ||
            !outgoingReferrals ||
            !uniqueMemberInfo ||
            !user.id ||
            isFetchingCaseloads ||
            isFetchingIncomingReferrals ||
            isFetchingOutgoingReferrals
        );
    }, [
        incomingReferrals,
        isFetchingCaseloads,
        isFetchingIncomingReferrals,
        isFetchingOutgoingReferrals,
        outgoingReferrals,
        uniqueMemberInfo,
        user.id
    ]);

    const navItems = ['Outgoing Referrals', 'Incoming Referrals'];

    const referralsToRender = useMemo(() => {
        return active === 'Outgoing Referrals'
            ? outgoingReferrals || []
            : incomingReferrals || [];
    }, [active, incomingReferrals, outgoingReferrals]);

    const fetchCaseloadsCallback = useCallback(
        () => fetchCaseloads(user.id),
        [fetchCaseloads, user.id]
    );

    const fetchReferralsCallback = useCallback(
        (userID: number, partnerID?: number) =>
            fetchReferrals(userID, partnerID),
        [fetchReferrals]
    );

    const resetReferralsCallback = useCallback(
        () => resetUserReferrals(),
        [resetUserReferrals]
    );

    const ReferralSkeletonLayout = () => {
        return (
            <Box>
                <Grid container spacing={4}>
                    {[0, 1, 2].map((index) => (
                        <Grid item key={index} xs={12}>
                            <Skeleton variant="rounded" height={300} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    };

    const matchHomelessToReferral = (referral: Referral) => {
        return (
            uniqueMemberInfo?.find(
                (homeless) => homeless.id === referral.homeless_id
            ) ?? null
        );
    };

    const matchHomelessToCaseload = (referral: Referral) => {
        return (
            caseloads?.find(
                (caseload) => caseload.homeless_id === referral.homeless_id
            ) ?? null
        );
    };

    useEffect(() => {
        if (!caseloads && !isFetchingCaseloads) {
            fetchCaseloadsCallback();
        }
    }, [caseloads, fetchCaseloadsCallback, isFetchingCaseloads]);

    useEffect(() => {
        if (user.id) {
            fetchReferralsCallback(user.id);
        }

        if (user.partner?.id) {
            fetchReferralsCallback(user.id, user.partner.id);
        }

        return () => {
            resetReferralsCallback();
        };
    }, [
        location.key,
        user.id,
        user.partner?.id,
        fetchReferralsCallback,
        resetReferralsCallback
    ]);

    useEffect(() => {
        if (!isObjectEmpty(user)) {
            setLayoutTitles('', 'Referrals');
        }
    }, [setLayoutTitles, user]);

    return (
        <div className={classes.referralsPage}>
            <Helmet title="Referrals" />
            <h1 className={classes.referralsTitle}>Your Referrals</h1>
            <div className={classes.referralsBody}>
                <Nav
                    active={active}
                    navItems={navItems}
                    setActive={setActive}
                />
                {isLoading ? (
                    <div className={classes.cardContainer}>
                        <ReferralSkeletonLayout />
                    </div>
                ) : (
                    <div className={classes.cardContainer}>
                        {(referralsToRender.length ?? 0) > 0 ? (
                            referralsToRender?.map((referral) => (
                                <ReferralCard
                                    key={referral.id}
                                    acceptReferral={acceptReferral}
                                    caseload={
                                        matchHomelessToCaseload(
                                            referral
                                        ) as Caseload
                                    }
                                    direction={direction}
                                    homeless={
                                        matchHomelessToReferral(
                                            referral
                                        ) as UniqueMemberInfo
                                    }
                                    profile={false}
                                    referral={referral}
                                    rejectReferral={rejectReferral}
                                    removeMemberFromMyMembers={
                                        removeMemberFromMyMembers
                                    }
                                    saveMemberToMyMembers={
                                        saveMemberToMyMembers
                                    }
                                    user={user}
                                />
                            ))
                        ) : (
                            <div className={classes.empty}>
                                <img
                                    alt="Empty Referrals"
                                    src={EmptyReferrals}
                                />
                                <div className={classes.refreshContainer}>
                                    <span>
                                        You currently don't have any {direction}{' '}
                                        referrals.
                                    </span>
                                    <CTAButton3
                                        onClick={() => resetReferralsCallback()}
                                    >
                                        Refresh
                                    </CTAButton3>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReferralsTab;
