import { useRef, useState } from 'react';

import AddButton from '../../../CaseManagement/components/AddButton';
import Card from '../../../CaseManagement/components/Card';
import EditIcon from '../../components/EditIcon';
import LoadingCircle from 'components/_shared/LoadingCircle';
import StoryIcon from 'assets/icon-story.png';
import { CTAButton1 } from 'components/_shared/buttons';

import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    lifeStoryCard: {
        position: 'relative',
        background:
            'linear-gradient(0deg, #252946 0%, #252946 100%), linear-gradient(0deg, #454981 0%, #454981 36%, rgba(69, 73, 129, 0.501961) 89.99%, rgba(69, 73, 129, 0.501961) 100%)',
        color: '#FFFFFF',
        padding: '20px 30px',
        '& input': {
            display: 'none'
        }
    },
    storyIcon: {
        position: 'absolute',
        top: -20,
        left: 0,
        width: '80px'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        fontSize: '24px',
        fontWeight: '800',
        lineHeight: '40px',
        letterSpacing: '8px',
        paddingLeft: '64px',
        marginBottom: '20px',
        '& button': {
            color: '#FFFFFF',
            fontSize: '24px'
        }
    },
    updatePhotoContainer: {
        position: 'relative'
    },
    updatePhoto: {
        maxWidth: '100%',
        height: 'auto',
        objectFit: 'cover',
        borderRadius: '20px'
    },
    body: {
        fontSize: '24px',
        fontWeight: '600',
        letterSpacing: '0.5px',
        height: '100%',
        margin: '20px 10px 80px 10px',
        position: 'relative',
        '& span': {
            position: 'relative',
            zIndex: '2'
        }
    },
    bodyInput: {
        width: '100%',
        border: '0',
        padding: '10px 0px 0px 0px',
        fontFamily: 'Manrope',
        fontSize: '1.75rem',
        fontWeight: '500',
        color: '#FFFFFF',
        borderRadius: '6px',
        letterSpacing: '-0.21px',
        background: 'transparent',
        resize: 'none',
        '&:placeholder-shown': {
            color: '#FFFFFF',
            opacity: '1',
            fontWeight: '600',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word'
        },
        '&:focus': {
            outline: 'none !important'
        }
    },
    btnDiv: {
        display: 'flex',
        alignItems: 'center',
        margin: '2rem 0'
    },
    btn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        minWidth: '300px',
        fontSize: '20px'
    }
}));

type LifeStoryUpdateProps = {
    id: number;
    isSubmitting: boolean;
    name: string;
    updateHomelessLifeStory: (data: any) => void;
};

const LifeStoryUpdate: React.FC<LifeStoryUpdateProps> = ({
    id,
    isSubmitting,
    name,
    updateHomelessLifeStory
}) => {
    const classes = useStyles();
    const imgRef = useRef<HTMLInputElement | null>(null);
    const imgUrlRef = useRef<null | string>(null);

    const [img, setImg] = useState(null);
    const [updateText, setUpdateText] = useState('');
    const [updateType, setUpdateType] = useState('Update');
    const [uploadImage, setUploadImage] = useState(false);

    const handleImageClick = () => {
        if (imgRef.current) {
            imgRef.current.click();
        }
    };

    const handleRemoveImageClick = () => {
        setUploadImage(false);
        imgUrlRef.current = null;
    };

    const handleSubmit = () => {
        const formData = new FormData();

        formData.append('holder_life_update[title]', updateType);
        formData.append('holder_life_update[description]', updateText);
        formData.append(
            'holder_life_update[category]',
            updateType === 'Update' ? 'story' : 'fact'
        );
        formData.append('holder_life_update[homeless_id]', id.toString());

        if (img) {
            formData.append('holder_life_update[photo]', img);
        }

        updateHomelessLifeStory(formData);
    };

    const handleTypeChange = () => {
        if (updateType === 'Update') {
            setUpdateText('');
            setUpdateType('Fun Fact');
        } else {
            setUpdateText('');
            setUpdateType('Update');
            handleRemoveImageClick();
        }
    };

    const handlePhotoUpload = (e: any) => {
        const img = e.target.files[0];

        if (img) {
            const imgUrl = URL.createObjectURL(img);

            imgUrlRef.current = imgUrl;

            setImg(img);
            setUploadImage(true);
        }
    };

    const renderPlaceholder = () => {
        if (updateType === 'Update') {
            return `Write an update to ${name} team here...`;
        } else {
            return `Share a fun fact about ${name} here...


                      Example questions...

           "What's a fun fact about you?"

            "What's your favorite song?"

                "What's your life dream?"`;
        }
    };

    return (
        <>
            <Card className={classes.lifeStoryCard}>
                <img
                    alt="story"
                    className={classes.storyIcon}
                    src={StoryIcon}
                />
                <div className={classes.title}>
                    {updateType}
                    <Button onClick={handleTypeChange}>
                        <i className="fas fa-chevron-down" />
                    </Button>
                </div>
                {uploadImage && updateType === 'Update' && (
                    <div className={classes.updatePhotoContainer}>
                        <img
                            alt="update"
                            className={classes.updatePhoto}
                            onClick={handleImageClick}
                            src={imgUrlRef.current!}
                        />
                        <EditIcon setEdit={handleRemoveImageClick} />
                    </div>
                )}
                {!uploadImage && updateType === 'Update' && (
                    <AddButton
                        action={handleImageClick}
                        type="photo"
                        style={{
                            color: '#FFFFFF',
                            flexDirection: 'column',
                            padding: '100px 0',
                            backgroundColor: 'transparent'
                        }}
                    />
                )}
                <input
                    accept="image/*"
                    id="photo-upload"
                    ref={imgRef}
                    title=""
                    type="file"
                    onChange={handlePhotoUpload}
                />
                <div
                    className={classes.body}
                    style={
                        updateType === 'Fun Fact'
                            ? { minHeight: '450px' }
                            : { minHeight: '100px' }
                    }
                >
                    <TextareaAutosize
                        className={classes.bodyInput}
                        id="life-story"
                        name="life-story"
                        onChange={(e) => setUpdateText(e.target.value)}
                        placeholder={renderPlaceholder()}
                        value={updateText}
                    />
                </div>
            </Card>
            <div className={classes.btnDiv}>
                <CTAButton1
                    className={classes.btn}
                    disabled={updateText.length === 0 || isSubmitting}
                    onClick={() => handleSubmit()}
                >
                    {isSubmitting ? <LoadingCircle /> : 'Submit'}
                </CTAButton1>
            </div>
        </>
    );
};

export default LifeStoryUpdate;
