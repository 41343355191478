import { ReactNode } from 'react';
import UpdateStatus from '../UpdateStatus';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        titleContainer: {},
        title: {
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 800,
            color: '#FFFFFF'
        },
        btnContainer: {
            padding: '20px 0'
        },
        btn: {
            borderRadius: '36px',
            width: '100%',
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 800,
            color: '#FFFFFF',
            padding: '10px 20px',
            textTransform: 'none'
        },
        modal: {
            position: 'fixed',
            left: '50%',
            top: '50%',
            zIndex: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            overflowY: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            margin: 'auto'
        },
        modalContent: {
            position: 'relative',
            top: '5%',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            height: 'fit-content',
            maxWidth: '660px',
            width: '80%'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: '16px',
            padding: '30px'
        }
    })
);

type SDOHPopUpProps = {
    content: ReactNode | string;
    catchUpID?: number;
    handleModalClick: (value: boolean) => void;
    updateStatus: boolean;
    setAddStatus?: (value: boolean) => void;
    setUpdateStatus: (value: boolean) => void;
    updateStatusProps: {
        answers: object[];
        id: number;
        name: null | string;
        statusId: null | number;
        statusQuestions: object[];
    };
};

const SDOHPopUp: React.FC<SDOHPopUpProps> = ({
    content,
    catchUpID,
    handleModalClick,
    updateStatus,
    setAddStatus,
    setUpdateStatus,
    updateStatusProps
}) => {
    const classes = useStyles();

    const { answers, id, name, statusId, statusQuestions } = updateStatusProps;

    return (
        <div className={classes.modal}>
            {!updateStatus && (
                <div className={classes.titleContainer}>
                    <span className={classes.title}>
                        Updating Member Statuses
                    </span>
                </div>
            )}
            <div
                className={classes.modalContent}
                style={{
                    height: !updateStatus || !statusId ? 'fit-content' : '100%'
                }}
            >
                {!updateStatus || !statusId ? (
                    <span className={classes.content}>{content}</span>
                ) : (
                    <UpdateStatus
                        answers={answers}
                        catchUpID={catchUpID}
                        id={id}
                        name={name || ''}
                        setAddStatus={setAddStatus}
                        setUpdateStatus={setUpdateStatus}
                        statusId={statusId || 0}
                        questions={statusQuestions}
                    />
                )}
                {!updateStatus && (
                    <div className={classes.btnContainer}>
                        <Button
                            className={classes.btn}
                            onClick={() => handleModalClick(false)}
                        >
                            Close
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SDOHPopUp;
