import { useState } from 'react';
import ReactGA from 'react-ga4';

import {
    Homeless,
    TouchpointAnswers,
    TouchpointQuestions
} from '../../../../../types';

import Status from './Status';

// TODO: Fix ts-ignore for progress
// @ts-ignore
import Progress from './Progress';

type Props = {
    addStatus: boolean;
    setAddStatus: (value: boolean) => void;
    exited: boolean;
    fetchTouchpointAnswersById: (id: number) => void;
    holder: Homeless;
    isFetchingTouchpointAnswersById: boolean;
    isSubmittingTouchpointAnswers: boolean;
    touchpointAnswers: TouchpointAnswers[];
    touchpointAnswersById: object;
    touchpointQuestions: TouchpointQuestions[];
    unsuccessful: boolean;
};

const SDOHStatus: React.FC<Props> = ({
    exited,
    fetchTouchpointAnswersById,
    holder,
    isSubmittingTouchpointAnswers,
    touchpointAnswers,
    touchpointQuestions,
    unsuccessful,
    ...props
}) => {
    const [active, setActive] = useState('Status');
    const [id, setId] = useState<null | number>(null);
    const [qol_id, setQolId] = useState<null | number>(null);

    const handleShowSDOHProgress = (id: number, qol_id: number) => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'SDOH Progress Click',
            label: `Progress for SDOH ID: ${id} and QOL ID: ${qol_id}`
        });

        if (qol_id) {
            fetchTouchpointAnswersById(qol_id);
        }

        if (active === 'Progress') {
            setActive('Status');
        } else {
            setActive('Progress');
        }

        setId(id);
        setQolId(qol_id);
    };

    const progressProps = {
        fetchTouchpointAnswersById,
        handleShowSdohProgress: handleShowSDOHProgress,
        holder,
        isFetchingTouchpointAnswersById: props.isFetchingTouchpointAnswersById,
        qolId: qol_id,
        setQolId,
        sdohId: id,
        touchpointAnswers,
        touchpointAnswersById: props.touchpointAnswersById,
        touchpointQuestions
    };

    const statusProps = {
        addStatus: props.addStatus,
        setAddStatus: props.setAddStatus,
        exited,
        holder,
        isSubmittingTouchpointAnswers,
        openProgress: handleShowSDOHProgress,
        touchpointAnswers,
        touchpointQuestions,
        unsuccessful
    };

    return (
        <>
            {active === 'Status' ? (
                <Status {...statusProps} />
            ) : (
                <Progress {...progressProps} />
            )}
        </>
    );
};

export default SDOHStatus;
