import { Fragment, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { TouchpointAnswers, TouchpointQuestions } from 'types';

import AddButton from '../../components/AddButton';
import Card from '../../components/Card';
import ChartIcon from 'assets/icon-chart.png';
import Empty from '../components/Empty';
import LoadingCircle from '../../../../../_shared/LoadingCircle';
import SDOHPopUp from '../components/SDOHPopUp';

import { formatDate, getColor } from '../utils';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        statusContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
        },
        statusCard: {
            background: '#FFFFFF',
            padding: '30px',
            margin: '0'
        },
        sdohItem: {
            padding: '10px 0 15px 0'
        },
        statusTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        statusIcon: {
            marginRight: '12px',
            fontSize: '22px',
            color: '#1F2152'
        },
        statusText: {
            fontSize: '16px',
            fontWeight: 700,
            color: '#1F2152',
            lineHeight: '24px'
        },
        statusWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '15px'
        },
        statusDescription: {
            display: 'flex',
            fontSize: '14px',
            fontWeight: 400,
            color: '#1F2152',
            lineHeight: '24px',
            width: '100%'
        },
        descriptionBody: {
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            margin: '0 40px 0 10px'
        },
        statusDate: {
            fontSize: '12px',
            fontWeight: 400,
            color: '#A6A6BF',
            lineHeight: '24px'
        },
        statusBar: {
            minWidth: '3px',
            borderRadius: '3px'
        },
        statusButton: {
            fontSize: '15px',
            fontWeight: 800,
            color: '#7378E8',
            lineHeight: '24px',
            cursor: 'pointer',
            textTransform: 'none',
            padding: '10px 20px',
            borderRadius: '36px',
            '&:hover': {
                color: '#918ED7'
            }
        },
        divider: {
            width: '100%',
            height: '1px',
            backgroundColor: '#E3E3FA',
            borderRadius: '1px',
            margin: '5px 0'
        },
        titleIconContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        chartIconContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '28px',
            height: '28px',
            borderRadius: '50%',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#EDEDFA'
            }
        },
        chartIcon: {
            width: '11px',
            height: '11px'
        },
        updateContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '20px'
        },
        updateButtonContainer: {
            padding: '5px 0'
        },
        updateButton: {
            fontSize: '20px',
            fontWeight: 800,
            padding: '10px 20px',
            borderRadius: '36px',
            color: '#7378E8',
            lineHeight: '27px',
            textTransform: 'none'
        }
    })
);

type Props = {
    addStatus: boolean;
    setAddStatus: (value: boolean) => void;
    exited: boolean;
    holder: {
        id: number;
        name: string;
    };
    isSubmittingTouchpointAnswers?: boolean;
    openProgress: (id: number, qol_id: number) => void;
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
    unsuccessful: boolean;
};

const Status: React.FC<Props> = ({
    addStatus,
    setAddStatus,
    exited,
    holder,
    isSubmittingTouchpointAnswers,
    openProgress,
    touchpointAnswers,
    touchpointQuestions,
    unsuccessful
}) => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const [answers, setAnswers] = useState<TouchpointAnswers[]>(
        touchpointAnswers || []
    );
    const [openUpdateStatus, setOpenUpdateStatus] = useState<boolean>(
        addStatus ? true : false
    );
    const [questionIds, setQuestionIds] = useState<number[]>([]);
    const [updateStatus, setUpdateStatus] = useState<boolean>(false);
    const [statusId, setStatusId] = useState<null | number>(null);

    const id = holder.id;
    const name = holder.name ? holder.name.split(' ')[0] : null;
    const statusQuestions = touchpointQuestions.filter(
        (question) =>
            question.type === 'StatusQuestion' ||
            question.type === 'QualityOfLifeMeasureQuestion'
    );

    const updateStatusProps = {
        answers,
        id,
        name,
        statusId,
        statusQuestions
    };

    useEffect(() => {
        const statusQuestionIds = statusQuestions.map(
            (question) => question.id
        );

        setQuestionIds([...statusQuestionIds]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!touchpointAnswers?.length || !questionIds.length) {
            return;
        }

        const processedAnswers = questionIds.map((id) => {
            const matchingAnswer = touchpointAnswers.find(
                (answer) => answer.touchpoint_question_id === id
            );

            if (matchingAnswer) {
                return matchingAnswer;
            } else {
                const touchpointQuestion = touchpointQuestions.find(
                    (question) => question.id === id
                );

                const sdohAnswer = touchpointAnswers.find(
                    (answer) =>
                        answer.quality_of_life_measure_id ===
                        touchpointQuestion?.quality_of_life_measure_id
                );

                if (sdohAnswer) {
                    if (sdohAnswer.answers?.length) {
                        return {
                            answers: [...sdohAnswer.answers],
                            quality_of_life_measure_id:
                                touchpointQuestion?.quality_of_life_measure_id,
                            touchpoint_question_id: id
                        };
                    } else {
                        return {
                            answers: [sdohAnswer],
                            quality_of_life_measure_id:
                                touchpointQuestion?.quality_of_life_measure_id,
                            touchpoint_question_id: id
                        };
                    }
                }
            }

            const multipleAnswers:
                | {
                      answers: {
                          question_type: string;
                          quality_of_life_measure_id: number;
                      }[];
                  }
                | undefined = touchpointAnswers.find(
                (answer) => answer.answers?.length
            );

            if (multipleAnswers) {
                const statusQuestionAnswer = multipleAnswers.answers.find(
                    (answer) =>
                        answer.question_type === 'StatusQuestion' &&
                        answer.quality_of_life_measure_id === id
                );

                if (statusQuestionAnswer) {
                    return statusQuestionAnswer;
                }
            }

            return {
                answer: null,
                quality_of_life_measure_id: id
            };
        });

        setAnswers([...(processedAnswers as any)]);
    }, [questionIds, touchpointAnswers, touchpointQuestions]);

    useEffect(() => {
        if (addStatus) {
            setOpenUpdateStatus(true);
        }
    }, [addStatus]);

    const handleCloseClick = () => {
        const statusContainer = document.getElementById('status-container');

        setAddStatus(false);
        setOpenUpdateStatus(false);
        setUpdateStatus(false);

        if (statusContainer) {
            statusContainer.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleUpdateStatus = (answer: {
        quality_of_life_measure_id: number;
        question_type: string;
        touchpoint_question_id: number;
    }) => {
        if (answer.question_type === 'StatusQuestion') {
            updateQuestionStatus(answer.touchpoint_question_id);
        } else {
            updateQuestionStatus(answer.quality_of_life_measure_id);
        }
    };

    const openStatusUpdate = () => {
        setOpenUpdateStatus(true);
    };

    const renderColor = (answer: {
        answer_index: number;
        classified_statuses: TouchpointAnswers['classified_statuses'];
        question_type: string;
    }) => {
        if (answer && answer.question_type === 'StatusQuestion') {
            const answers =
                answer.classified_statuses[0]?.classified_status_answer_matches;

            if (answers && answers.length) {
                const highestAnswer = answers.reduce((prev, curr) =>
                    curr.rank > prev.rank ? curr : prev
                );

                return getColor(highestAnswer.rank - 1, 'primary', 5);
            }
            return '#999DFF';
        }

        if (answer.answer_index !== null) {
            return getColor(answer.answer_index, 'primary', 5);
        } else {
            return '#999DFF';
        }
    };

    const renderStatus = () => {
        if (isSubmittingTouchpointAnswers) {
            return <LoadingCircle />;
        }

        return statusQuestions.map((question, questionIndex) => {
            const { friendly_name, icon_name, id, quality_of_life_measure_id } =
                question;
            const answerArr = [] as {
                answer: string | null;
                answers?: any;
                quality_of_life_measure_id?: number;
            }[];

            if (answers.length && question) {
                const matchingAnswers = answers.filter(
                    (answer) =>
                        answer.quality_of_life_measure_id ===
                        quality_of_life_measure_id
                );

                if (matchingAnswers.length) {
                    answerArr.push(...matchingAnswers);
                } else {
                    answerArr.push({
                        answer: null,
                        quality_of_life_measure_id: quality_of_life_measure_id
                    });
                }
            } else {
                answerArr.push({
                    answer: null,
                    quality_of_life_measure_id: quality_of_life_measure_id
                });
            }

            return answerArr?.map((answer, index) => {
                const hasStatus = !!answer.answers || !!answer.answer;
                let statusAnswer = answer.answers ? answer.answers[0] : answer;

                const statusBarColor = hasStatus
                    ? renderColor(statusAnswer)
                    : '#E3E3FA';

                const statusDescription = hasStatus ? (
                    <div className={classes.descriptionBody}>
                        {statusAnswer.question_type !== 'StatusQuestion' &&
                        statusAnswer.answer_slider_option
                            ? statusAnswer.answer_slider_option[0]?.description
                            : statusAnswer.answer}
                        <span className={classes.statusDate}>
                            On {formatDate(statusAnswer.created_at)}
                        </span>
                    </div>
                ) : (
                    <div className={classes.descriptionBody}>No Status Set</div>
                );

                return (
                    <Fragment key={index}>
                        <div className={classes.sdohItem} key={question.id}>
                            <div className={classes.statusTitle}>
                                <div className={classes.titleIconContainer}>
                                    <i
                                        className={`far fa-${icon_name} ${classes.statusIcon}`}
                                    />
                                    <span className={classes.statusText}>
                                        {friendly_name}
                                    </span>
                                </div>
                                {!openUpdateStatus && (
                                    <div
                                        className={classes.chartIconContainer}
                                        onClick={() =>
                                            openProgress(
                                                id,
                                                quality_of_life_measure_id
                                            )
                                        }
                                    >
                                        <img
                                            className={classes.chartIcon}
                                            src={ChartIcon}
                                            alt="chart"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={classes.statusWrapper}>
                                <div className={classes.statusDescription}>
                                    <div
                                        className={classes.statusBar}
                                        style={{
                                            backgroundColor: statusBarColor
                                        }}
                                    />
                                    {statusDescription}
                                </div>
                                {openUpdateStatus && (
                                    <Button
                                        className={classes.statusButton}
                                        onClick={() =>
                                            handleUpdateStatus(statusAnswer)
                                        }
                                    >
                                        {hasStatus ? 'Update' : 'Add'}
                                    </Button>
                                )}
                            </div>
                        </div>
                        {questionIndex < statusQuestions.length - 1 && (
                            <div className={classes.divider} />
                        )}
                    </Fragment>
                );
            });
        });
    };

    const updateQuestionStatus = (questionID: number) => {
        setUpdateStatus(true);
        setStatusId(questionID);
    };

    return (
        <>
            <div className={classes.statusContainer} id="status-container">
                <Card info exited status name={name || ''} />
                {!touchpointAnswers || !touchpointAnswers.length ? (
                    <Empty
                        exited={exited}
                        name={name || ''}
                        openSDOH={() => history.push(`${match.url}/qol`)}
                    />
                ) : (
                    <Card className={classes.statusCard}>{renderStatus()}</Card>
                )}
                {touchpointAnswers &&
                    touchpointAnswers.length &&
                    !exited &&
                    !unsuccessful && (
                        <AddButton
                            action={() => openStatusUpdate()}
                            type={'update'}
                        />
                    )}
            </div>
            {openUpdateStatus && (
                <SDOHPopUp
                    content={renderStatus() as any}
                    handleModalClick={() => handleCloseClick()}
                    updateStatus={updateStatus}
                    setUpdateStatus={setUpdateStatus}
                    updateStatusProps={updateStatusProps}
                />
            )}
        </>
    );
};

export default Status;
