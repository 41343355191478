import * as constants from '../constants/constants';

import { Referral } from 'types';

const initialState = {
    incomingReferrals: null,
    isFetchingIncomingReferrals: false,
    isFetchingMemberReferrals: false,
    isFetchingOutgoingReferrals: false,
    uniqueMemberInfo: null,
    memberReferrals: null,
    outgoingReferrals: null
};

export default function referrals_reducer(state = initialState, action: any) {
    let updatedIncomingReferrals: Referral[] | null;
    let updatedMemberReferrals: Referral[] | null;

    switch (action.type) {
        case constants.ACCEPT_REFERRAL:
            updatedIncomingReferrals = state.incomingReferrals
                ? (state.incomingReferrals as Referral[]).map(
                      (referral: Referral) => {
                          if (referral.id === action.payload) {
                              referral.is_accepted = true;
                          }

                          return referral;
                      }
                  )
                : null;

            updatedMemberReferrals = state.memberReferrals
                ? (state.memberReferrals as Referral[]).map(
                      (referral: Referral) => {
                          if (referral.id === action.payload) {
                              referral.is_accepted = true;
                          }

                          return referral;
                      }
                  )
                : null;

            return {
                ...state,
                memberReferrals:
                    updatedMemberReferrals || state.memberReferrals,
                incomingReferrals:
                    updatedIncomingReferrals || state.incomingReferrals
            };
        case constants.FETCH_INCOMING_REFERRALS:
            return {
                ...state,
                isFetchingIncomingReferrals: true
            };
        case constants.FETCH_MEMBER_REFERRALS:
            return {
                ...state,
                isFetchingMemberReferrals: true
            };
        case constants.FETCH_OUTGOING_REFERRALS:
            return {
                ...state,
                isFetchingOutgoingReferrals: true
            };
        case constants.REJECT_REFERRAL:
            updatedIncomingReferrals = state.incomingReferrals
                ? (state.incomingReferrals as Referral[]).map(
                      (referral: Referral) => {
                          if (referral.id === action.payload) {
                              referral.is_rejected = true;
                          }

                          return referral;
                      }
                  )
                : null;

            updatedMemberReferrals = state.memberReferrals
                ? (state.memberReferrals as Referral[]).map(
                      (referral: Referral) => {
                          if (referral.id === action.payload) {
                              referral.is_rejected = true;
                          }

                          return referral;
                      }
                  )
                : null;

            return {
                ...state,
                memberReferrals:
                    updatedMemberReferrals || state.memberReferrals,
                incomingReferrals:
                    updatedIncomingReferrals || state.incomingReferrals
            };
        case constants.RESET_MEMBER_REFERRALS:
            return {
                ...state,
                memberReferrals: null
            };
        case constants.RESET_USER_REFERRALS:
            return {
                ...state,
                incomingReferrals: null,
                outgoingReferrals: null
            };
        case constants.SET_INCOMING_REFERRALS:
            const sortedIncomingReferrals = state.incomingReferrals
                ? [...state.incomingReferrals, ...action.payload].sort(
                      (a: Referral, b: Referral) => {
                          return (
                              new Date(b.created_at).getTime() -
                              new Date(a.created_at).getTime()
                          );
                      }
                  )
                : [];

            return {
                ...state,
                incomingReferrals: state.incomingReferrals
                    ? sortedIncomingReferrals
                    : action.payload,
                isFetchingIncomingReferrals: false
            };
        case constants.SET_MEMBER_REFERRALS:
            return {
                ...state,
                memberReferrals: action.payload,
                isFetchingMemberReferrals: false
            };
        case constants.SET_OUTGOING_REFERRALS:
            return {
                ...state,
                outgoingReferrals: state.outgoingReferrals
                    ? [...state.outgoingReferrals, ...action.payload]
                    : action.payload,
                isFetchingOutgoingReferrals: false
            };
        case constants.SET_UNIQUE_MEMBER_INFO:
            return {
                ...state,
                uniqueMemberInfo: state.uniqueMemberInfo
                    ? [...state.uniqueMemberInfo, ...action.payload]
                    : action.payload
            };
        case constants.UPDATE_MEMBER_REFERRALS:
            updatedMemberReferrals = state.memberReferrals
                ? [...state.memberReferrals, action.payload]
                : [action.payload];

            return {
                ...state,
                memberReferrals: updatedMemberReferrals
            };
        default:
            return state;
    }
}
