import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';
import { animated, Transition } from 'react-spring';
import PropTypes from 'prop-types';

import LoadingCircle from '../_shared/LoadingCircle';
import Profile from './profile_2.0';

import { checkConfirmUser } from 'api/user.ts';

import AuthManager from '../../auth';
import { isObjectEmpty } from '../../util';

import { withStyles } from '@mui/styles';

const styles = () => ({
    root: {
        width: '100%',
        height: 'calc(100%)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
        position: 'relative',
        zIndex: 0
    },
    profileHeader: {
        display: 'flex',
        height: 50,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        background: '#202356',
        '@media only screen and (min-width: 450px)': {
            height: '50px',
            flexDirection: 'row'
        },
        '@media only screen and (min-width: 1024px)': {
            paddingRight: '48px',
            width: 'calc(100% - 48px)'
        },
        '@media only screen and (min-width: 1580px)': {}
    },
    base: {
        padding: '0px 13px',
        lineHeight: '35px',
        height: '35px',
        borderRadius: '25px',
        background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
        color: '#fff',
        margin: '10px 0 10px 20px'
    },
    loading: {
        background: 'linear-gradient(180deg, #202356 0%, #17132E 100%)',
        width: '100%',
        height: '100%'
    }
});

class HolderProfile extends Component {
    constructor(props) {
        super(props);

        this.authInstance = AuthManager.getInstance();
        this.id = parseInt(props.match.params.id);
        this.rootRef = React.createRef();
        this.state = {
            canConfirmMembership: false,
            dataReady: false,
            isLoading: false
        };
    }

    componentDidMount() {
        if (this.props.userState === 'idle') {
            return;
        }

        this.fetchData();

        ReactGA.send({
            hitType: 'pageview',
            page: `/holders/${this.id}`
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.touchpointAnswers !== this.props.touchpointAnswers) {
            this.getTouchpointAnswers();
        }

        if (
            this.props.userState === 'success' &&
            prevProps.userState === 'idle'
        ) {
            this.fetchData();
        }

        if (this.state.dataReady && !prevState.dataReady) {
            this.calculateCompletedProfileItems();
        }

        if (
            this.props.supplementalQuestionIds !==
            prevProps.supplementalQuestionIds
        ) {
            this.getSupplementalQuestionAnswers();
        }

        this.updateLayoutSubtitle();
    }

    calculateCompletedProfileItems = () => {
        const {
            homelessGoalActions,
            homelessInfo,
            homelessNeeds,
            profileItems,
            setProfileCompletedItems,
            supplementalQuestions,
            supplementalQuestionAnswers,
            touchpointAnswers
        } = this.props;

        if (this.state.dataReady) {
            const completedItems = Object.keys(profileItems).filter((item) => {
                switch (item) {
                    case 'action_steps':
                        return (
                            homelessGoalActions &&
                            homelessGoalActions.length > 0
                        );
                    case 'contact_info':
                        return homelessInfo.email || homelessInfo.phone_number;
                    case 'full_name':
                        return (
                            homelessInfo.first_name || homelessInfo.last_name
                        );
                    case 'needs':
                        return homelessNeeds && homelessNeeds.length > 0;
                    // case 'photo':
                    //     return !homelessInfo.photo.includes(
                    //         'grey_scale_samaritan_member_default'
                    //     );
                    case 'status':
                        if (!touchpointAnswers) {
                            return true;
                        }

                        return (
                            touchpointAnswers && touchpointAnswers.length > 0
                        );
                    case 'supplemental_questions':
                        if (
                            !supplementalQuestionAnswers ||
                            supplementalQuestions.length === 0
                        ) {
                            return true;
                        }

                        return (
                            supplementalQuestionAnswers &&
                            supplementalQuestionAnswers.length > 0
                        );
                    case item:
                        return homelessInfo[item];
                    default:
                        return false;
                }
            });

            setProfileCompletedItems(completedItems);
        }
    };

    fetchData = async () => {
        try {
            this.setState({ isLoading: true });

            await Promise.all([
                this.getCaseloads(),
                this.getCatchUpItems(),
                this.getDebitCardOptions(),
                this.getDeploymentInfo(),
                this.getHistoryFilters(),
                this.getHomelessInfo(),
                this.getReferrals(),
                this.getSupplementalQuestions(),
                this.getTouchpointQuestions()
            ]);

            await this.getConfirmUserStatus();
            await this.getSupplementalQuestionAnswers();
            await this.getTouchpointAnswers();

            this.setState({ dataReady: true, isLoading: false });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ isLoading: false });
        }
    };

    getCaseloads = async () => {
        const { caseloads, getCaseloads } = this.props;

        if (!caseloads) {
            await getCaseloads();
        }
    };

    getCatchUpItems = async () => {
        const { getCatchUpItems } = this.props;

        await getCatchUpItems(this.id, ['active', 'snoozed']);
    };

    getConfirmUserStatus = async () => {
        const { homelessInfo } = this.props;

        if (!homelessInfo || homelessInfo.profile_stage !== 'PENDING') return;

        const response = await checkConfirmUser(this.id);
        const resJson = await response.json();

        const { can_confirm_membership } = resJson;

        this.setState({ canConfirmMembership: can_confirm_membership });
    };

    getDebitCardOptions = async () => {
        const { debitCardOptions, getDebitCardOptions } = this.props;

        if (!debitCardOptions) {
            await getDebitCardOptions();
        }
    };

    getDeploymentInfo = async () => {
        const {
            deploymentOrgs,
            deploymentUsers,
            getDeploymentOrgs,
            getDeploymentUsers,
            user
        } = this.props;

        const deploymentIDs = user.partner?.deployment_ids;

        if (!deploymentUsers) {
            await getDeploymentUsers();
        }

        if (!deploymentOrgs) {
            await Promise.all(deploymentIDs.map((id) => getDeploymentOrgs(id)));
        }
    };

    getHistoryFilters = async () => {
        const { getHistoryFilters, historyFilters } = this.props;

        if (!historyFilters.length) {
            await getHistoryFilters();
        }
    };

    getHomelessInfo = async () => {
        const {
            getHomelessInfo,
            getHomelessLifeStory,
            getDebitCardInfo,
            homelessInfo,
            isFetchingHomelessInfo,
            isFetchingHomelessLifeStory,
            notFoundHomelessInfo
        } = this.props;

        const holderIsNotCurrentHolder =
            !isObjectEmpty(homelessInfo) && this.id !== homelessInfo.id;

        if (
            !isFetchingHomelessInfo &&
            !isFetchingHomelessLifeStory &&
            !notFoundHomelessInfo &&
            (isObjectEmpty(homelessInfo) || holderIsNotCurrentHolder)
        ) {
            await getHomelessInfo(
                this.authInstance.getToken(),
                this.authInstance.getApiToken(),
                this.beaconId,
                this.id
            );
            await getHomelessLifeStory(this.id);
            await getDebitCardInfo(this.id);
        }
    };

    getReferrals = async () => {
        const { getReferrals, homelessInfo, memberReferrals } = this.props;

        const holderIsNotCurrentHolder =
            !isObjectEmpty(homelessInfo) && this.id !== homelessInfo.id;

        if (!memberReferrals || holderIsNotCurrentHolder) {
            await getReferrals(this.id);
        }
    };

    getSupplementalQuestions = async () => {
        const {
            getSupplementalQuestionsRequest,
            isFetchingSupplementalQuestions,
            supplementalQuestions
        } = this.props;

        if (
            !isFetchingSupplementalQuestions &&
            supplementalQuestions.length === 0
        ) {
            await getSupplementalQuestionsRequest();
        }
    };

    getSupplementalQuestionAnswers = async () => {
        const {
            getSupplementalQuestionAnswersRequest,
            homelessInfo,
            supplementalQuestionAnswers,
            supplementalQuestionIds
        } = this.props;

        const hasQuestionIds = supplementalQuestionIds.length > 0;
        const hasNoAnswers =
            !supplementalQuestionAnswers ||
            supplementalQuestionAnswers.length === 0;
        const differentId = this.id !== homelessInfo.id;

        if (
            (hasQuestionIds && hasNoAnswers && differentId) ||
            (hasQuestionIds && hasNoAnswers && !differentId)
        ) {
            await getSupplementalQuestionAnswersRequest(
                supplementalQuestionIds,
                this.id
            );
        }
    };

    getTouchpointAnswers = async () => {
        const {
            getTouchpointAnswersRequest2,
            homelessInfo,
            touchpointAnswers,
            touchpointQuestionIds,
            touchpointQuestionQOLs
        } = this.props;

        const differentId = this.id !== homelessInfo.id;
        const hasNoAnswers =
            !touchpointAnswers || touchpointAnswers.length === 0;
        const hasQuestionIds = touchpointQuestionIds.length > 0;

        if (
            (hasQuestionIds && hasNoAnswers && differentId) ||
            (hasQuestionIds && hasNoAnswers && !differentId)
        ) {
            getTouchpointAnswersRequest2(
                this.id,
                touchpointQuestionIds,
                touchpointQuestionQOLs
            );
        }
    };

    getTouchpointAnswersById = async (questionId) => {
        const { getTouchpointAnswersByIdRequest, isFetchingTouchpointAnswer } =
            this.props;

        if (!isFetchingTouchpointAnswer) {
            getTouchpointAnswersByIdRequest(this.id, questionId);
        }
    };

    getTouchpointQuestions = async () => {
        const {
            determinantRanks,
            getDeterminantRanks,
            getTouchpointQuestionsRequest,
            isFetchingDeterminantRanks,
            isFetchingTouchpointQuestions,
            touchpointQuestions
        } = this.props;

        if (!isFetchingDeterminantRanks && determinantRanks.length === 0) {
            getDeterminantRanks(this.authInstance.getApiToken());
        }

        if (
            !isFetchingTouchpointQuestions &&
            touchpointQuestions.length === 0
        ) {
            await getTouchpointQuestionsRequest();
        }
    };

    updateLayoutSubtitle() {
        const { homelessInfo, layoutTitles, setLayoutTitles } = this.props;
        const { subtitle } = layoutTitles;

        if (
            homelessInfo &&
            homelessInfo.hasOwnProperty('name') &&
            homelessInfo.hasOwnProperty('handle')
        ) {
            const newSubtitle = `${homelessInfo.name}. (${homelessInfo.handle})`;

            if (newSubtitle !== subtitle) {
                setLayoutTitles(this.layoutTitle, newSubtitle);
            }
        }
    }

    render() {
        const {
            classes,
            homelessInfo,
            isFetchingCatchUpItems,
            isFetchingDebitCardOptions,
            isFetchingDeploymentOrgs,
            isFetchingDeploymentUsers,
            isFetchingHomelessInfo,
            isFetchingHomelessGoals,
            isFetchingHomelessGoalActions,
            isFetchingHomelessLifeStory,
            isFetchingHomelessNeeds,
            isFetchingSupplementalQuestions,
            isFetchingSupplementalQuestionAnswers,
            isFetchingTouchpointAnswers,
            isFetchingTouchpointQuestions,
            isFetchingTouchpointQuestionIds,
            isFetchingTouchpointQuestionQOLs,
            location,
            match,
            notFoundHomelessInfo
        } = this.props;

        if (notFoundHomelessInfo)
            return <div className={classes.root}>no holder found!</div>;

        const isFetching =
            isFetchingCatchUpItems ||
            isFetchingDebitCardOptions ||
            isFetchingDeploymentOrgs ||
            isFetchingDeploymentUsers ||
            isFetchingHomelessInfo ||
            isFetchingHomelessGoals ||
            isFetchingHomelessGoalActions ||
            isFetchingHomelessLifeStory ||
            isFetchingHomelessNeeds ||
            isFetchingSupplementalQuestions ||
            isFetchingSupplementalQuestionAnswers ||
            isFetchingTouchpointAnswers ||
            isFetchingTouchpointQuestions ||
            isFetchingTouchpointQuestionIds ||
            isFetchingTouchpointQuestionQOLs ||
            this.state.isLoading;

        if (isFetching)
            return (
                <div className={classes.loading}>
                    <LoadingCircle />
                </div>
            );

        if (!homelessInfo || isObjectEmpty(homelessInfo)) return null;
        if (!homelessInfo) return null;

        return (
            <>
                <Helmet title={homelessInfo.name} />
                <div className={classes.root} ref={this.rootRef}>
                    <Transition
                        items={location}
                        initial={{
                            position: 'absolute',
                            transform: 'translateX(0%)',
                            height: '100%',
                            width: '100%'
                        }}
                        from={{
                            position: 'absolute',
                            transform: 'translateX(-100%)',
                            height: '100%',
                            width: '100%'
                        }}
                        enter={{
                            transform: 'translateX(0%)',
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            overflow: 'auto'
                        }}
                        leave={{
                            transform: 'translateX(100%)',
                            position: 'absolute'
                        }}
                    >
                        {(props, loc) => (
                            <animated.div style={props}>
                                <Switch location={loc}>
                                    <Route
                                        path={match.url}
                                        render={() => (
                                            <Profile {...this.profileProps} />
                                        )}
                                    />
                                </Switch>
                            </animated.div>
                        )}
                    </Transition>
                </div>
            </>
        );
    }

    get profileProps() {
        const {
            acceptReferral,
            assignedBonusAmount,
            assignDebitCard,
            caseloads,
            catchUpItems,
            createMemberCaseload,
            debitHistory,
            debitInfo,
            debitCardOptions,
            deploymentOrgs,
            deploymentUsers,
            getApiErrorResponse,
            getDebitCardInfo,
            getDebitCardHistory,
            getHomelessLifeStory,
            getMemberPayments,
            goalActionCompletePopupId,
            historyFilters,
            homelessGoalActions,
            homelessInfo,
            homelessLifeStory,
            homelessLifeStorySuccess,
            homelessNeeds,
            improvedStatus,
            improvedStatusCount,
            intakeId,
            isEditingGoalAction,
            isFetchingHomelessGoalActions,
            isFetchingHomelessLifeStory,
            isFetchingHomelessNeeds,
            isFetchingMemberPayments,
            isFetchingDebitHistory,
            isFetchingDebitInfo,
            isFetchingTouchpointAnswersById,
            isReferringMember,
            isSubmittingHomelessLifeStory,
            isSubmittingTouchpointAnswers,
            maxBonusAmount,
            memberPayments,
            memberReferrals,
            newHomelessGoalActions,
            newHomelessNeeds,
            previousAnswers,
            profileItems,
            redemptionList,
            referMember,
            referMemberError,
            rejectReferral,
            remainingBonusAmount,
            removeMemberFromMyMembers,
            resetIntake,
            route,
            saveMemberToMyMembers,
            setGoalActionCompletePopupId,
            setProfileCompletedItems,
            showGoalActionCompletePopup,
            supplementalQuestions,
            supplementalQuestionAnswers,
            toggleGoalActionCompletePopup,
            touchpointAnswers,
            touchpointAnswersById,
            touchpointQuestions,
            touchpointQuestionQOLs,
            updatedAnswerIds,
            updatedQuestionIds,
            updateGoalActionMatchAmount,
            updateGoalActionStatus,
            updateHomelessInfoField,
            updateHomelessLifeStory,
            updateProfileCompletedItems,
            user
        } = this.props;

        return {
            acceptReferral,
            assignedBonusAmount,
            assignDebitCard,
            canConfirmMembership: this.state.canConfirmMembership,
            caseloads,
            catchUpItems,
            createMemberCaseload,
            debitHistory,
            debitInfo,
            debitCardOptions,
            deploymentOrgs,
            deploymentUsers,
            getApiErrorResponse,
            getDebitCardInfo,
            getDebitCardHistory,
            getHomelessLifeStory,
            getMemberPayments,
            getTouchpointAnswersById: this.getTouchpointAnswersById,
            goalActionCompletePopupId,
            historyFilters,
            homelessGoalActions,
            homelessInfo,
            homelessLifeStory,
            homelessLifeStorySuccess,
            homelessNeeds,
            improvedStatus,
            improvedStatusCount,
            intakeId,
            isEditingGoalAction,
            isFetchingHomelessGoalActions,
            isFetchingHomelessLifeStory,
            isFetchingHomelessNeeds,
            isFetchingMemberPayments,
            isFetchingDebitHistory,
            isFetchingDebitInfo,
            isFetchingTouchpointAnswersById,
            isReferringMember,
            isSubmittingHomelessLifeStory,
            isSubmittingTouchpointAnswers,
            maxBonusAmount,
            memberPayments,
            memberReferrals,
            newHomelessGoalActions,
            newHomelessNeeds,
            previousAnswers,
            profileItems,
            redemptionList,
            referMember,
            referMemberError,
            rejectReferral,
            remainingBonusAmount,
            removeMemberFromMyMembers,
            resetIntake,
            route,
            saveMemberToMyMembers,
            setGoalActionCompletePopupId,
            setProfileCompletedItems,
            showGoalActionCompletePopup,
            supplementalQuestionAnswers,
            supplementalQuestions,
            toggleGoalActionCompletePopup,
            touchpointAnswers,
            touchpointAnswersById,
            touchpointQuestionQOLs,
            touchpointQuestions,
            updatedAnswerIds,
            updatedQuestionIds,
            updateGoalActionMatchAmount,
            updateGoalActionStatus,
            updateHomelessInfoField,
            updateHomelessLifeStory,
            updateProfileCompletedItems,
            user
        };
    }
}

HolderProfile.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HolderProfile);
