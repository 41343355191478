import * as CONSTANTS from '../constants/constants';
import randomString from '../../util/randomString';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import { memoNeedStatusUpdated } from './local';

import * as api from '../../api/needs';
import { postHomelessGoal } from '../../api/goals';
import { postingNewGoal, postedNewGoal, failedNewGoal } from './goals';

function fetchHomelessNeeds() {
    return {
        type: CONSTANTS.GET_HOMELESS_NEEDS
    };
}

function setHomelessNeeds(response: any) {
    return {
        type: CONSTANTS.SET_HOMELESS_NEEDS,
        payload: response
    };
}

export function getHomelessNeeds(_token: string, id: number) {
    return (dispatch: Function) => {
        dispatch(fetchHomelessNeeds());
        return api
            .fetchHomelessNeeds(id)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { needs: any[] }) => {
                return dispatch(setHomelessNeeds(responseJson.needs));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

function changeLocalNeedStatus(id: number, status: string) {
    return {
        type: CONSTANTS.CHANGE_LOCAL_NEED_STATUS,
        need_id: id,
        status
    };
}

export function completeNeed(id: number) {
    return (dispatch: Function) => {
        return api
            .completeNeed(id)
            .then((response: any) => {
                if (response && response.ok) {
                    return response.json();
                }
                throw new Error(`${response.status}: ${response.statusText}`);
            })
            .then((responseJson: { need: { id: number } }) => {
                dispatch(memoNeedStatusUpdated(responseJson.need.id));
                return dispatch(
                    changeLocalNeedStatus(responseJson.need.id, 'completed')
                );
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function cancelNeed(id: number) {
    return async (dispatch: Function) => {
        try {
            let res = await api.cancelNeed(id);
            if (res && res.ok) {
                let resJson = await res.json();
                dispatch(changeLocalNeedStatus(resJson.need.id, 'cancelled'));
                return dispatch(memoNeedStatusUpdated(resJson.need.id));
            }
            throw new Error(`${res.status}: ${res.statusText}`);
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
}

// TODO: Fix any types
function postingNewNeed(payload: any, tempRef: string) {
    return {
        type: CONSTANTS.POSTING_NEW_HOMELESS_NEED,
        payload,
        tempRef
    };
}

function postedNewNeed(tempRef: string, newNeed: any) {
    return {
        type: CONSTANTS.POSTED_NEW_HOMELESS_NEED,
        tempRef,
        newNeed
    };
}

function editingNeed(payload: any, tempRef: string) {
    return {
        type: CONSTANTS.EDITING_HOMELESS_NEED,
        payload,
        tempRef
    };
}

function editedNeed(tempRef: string, newNeed: any) {
    return {
        type: CONSTANTS.EDITED_HOMELESS_NEED,
        tempRef,
        newNeed
    };
}

function failedNewNeed(tempRef: string, error: string) {
    return {
        type: CONSTANTS.FAILED_NEW_HOMELESS_NEED,
        tempRef,
        error
    };
}

function failedEditNeed(tempRef: string, error: string) {
    return {
        type: CONSTANTS.FAILED_EDIT_HOMELESS_NEED,
        tempRef,
        error
    };
}

export function newNeedThunk(
    need: string,
    amount: number,
    date: string,
    needId?: number,
    _qolIds?: number,
    customGoal?: string
) {
    const tempRef = randomString();

    return async (dispatch: Function, getState: any) => {
        const state = getState();
        const homelessId = state.homeless.homelessInfo.id;
        const currentGoals = state.goals.homelessGoals;
        const matchedGoal = customGoal
            ? currentGoals.find(
                  (goal: { description: string }) =>
                      goal.description === customGoal
              )
            : null;

        let goal_id = matchedGoal?.id ?? null;

        if (!matchedGoal && customGoal) {
            const goalPayload = {
                goal: { homelessId, description: customGoal } as {
                    homelessId: number;
                    description: string;
                }
            };
            dispatch(postingNewGoal(goalPayload, tempRef));

            const res = await postHomelessGoal(goalPayload.goal);
            if (!res.ok) {
                const error = await res.json();
                return dispatch(failedNewGoal(tempRef, error.error));
            }

            const { goal } = await res.json();
            dispatch(postedNewGoal(tempRef, goal));
            goal_id = goal.id;
        }

        const needPayload = {
            need: {
                amount,
                description: need,
                due_at: date,
                goal_id,
                homeless_id: needId ? undefined : homelessId
            }
        };

        dispatch(
            needId
                ? editingNeed(needPayload, tempRef)
                : postingNewNeed(needPayload, tempRef)
        );

        const res = needId
            ? await api.editNeed(needPayload, needId)
            : await api.postHomelessNeed(needPayload);

        let resJson;

        if (!res || typeof res.json !== 'function') {
            return dispatch(
                needId
                    ? failedEditNeed(tempRef, 'Invalid server response')
                    : failedNewNeed(tempRef, 'Invalid server response')
            );
        }

        try {
            resJson = await res.json();
        } catch (error) {
            return dispatch(
                needId
                    ? failedEditNeed(tempRef, 'Error parsing server response')
                    : failedNewNeed(tempRef, 'Error parsing server response')
            );
        }

        if (!res.ok) {
            const errorDescription =
                resJson?.errors?.[0]?.description || 'Unknown error';
            return dispatch(
                needId
                    ? failedEditNeed(tempRef, errorDescription)
                    : failedNewNeed(tempRef, errorDescription)
            );
        }

        resJson.need.relations = { donations: [], encouragements: [] };

        return dispatch(
            needId
                ? editedNeed(tempRef, resJson.need)
                : postedNewNeed(tempRef, resJson.need)
        );
    };
}