import classNames from 'classnames';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        pending: {
            backgroundColor: '#FEF3E7',
            color: '#7A4406'
        },
        referredTag: {
            position: 'absolute',
            left: '0',
            top: '16px',
            borderRadius: '0 4px 4px 0',
            fontSize: '14px',
            fontWeight: 700,
            letterSpacing: '2px',
            lineHeight: '14px',
            padding: '4px 12px',
            textTransform: 'uppercase',
            zIndex: 1
        },
        referred: {
            backgroundColor: '#E7EFFE',
            color: '#062D7A'
        },
        referredCard: {
            backgroundColor: '#FEF3E7',
            color: '#7A4406'
        },
        referralAccepted: {
            backgroundColor: '#E9FBF2',
            color: '#1B9757'
        },
        referralRejected: {
            backgroundColor: '#FEE7EC',
            color: '#A80A2D'
        }
    })
);

type ReferredTagProps = {
    popUp?: boolean;
    text: string;
};

const ReferredTag: React.FC<ReferredTagProps> = ({ popUp, text }) => {
    const classes = useStyles();

    const tagClass = classNames({
        [classes.referredTag]: true,
        [classes.pending]: text === 'pending',
        [classes.referred]:
            text === 'referred' || (text === 'referred to you' && !popUp),
        [classes.referredCard]: text === 'referred to you' && popUp,
        [classes.referralAccepted]:
            text === 'referral accepted' || text === 'membership confirmed',
        [classes.referralRejected]:
            text === 'referral rejected' || text === 'pending membership ended'
    });

    return <span className={tagClass}>{text}</span>;
};

export default ReferredTag;
