import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            padding: '10px 20px',
            textTransform: 'none',
            width: '100%'
        },
        btnContainer: {
            position: 'relative',
            paddingBottom: '20px',
            marginBottom: '24px',
            zIndex: 5
        },
        modal: {
            position: 'fixed',
            left: '50%',
            top: '50%',
            zIndex: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            overflowY: 'auto',
            width: '100%'
        },
        modalContent: {
            position: 'relative',
            top: '5%',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            boxSizing: 'border-box',
            height: '100%',
            maxWidth: '420px',
            width: '100%',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        modalTitle: {
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            marginTop: '24px',
            padding: '30px 0 10px 0'
        }
    })
);

type UpdateModalProps = {
    children: React.ReactNode;
    closeModalClick: (close: boolean) => void;
    title: string;
};

const UpdateModal: React.FC<UpdateModalProps> = ({
    children,
    closeModalClick,
    title
}) => {
    const classes = useStyles();

    return (
        <Fade in>
            <div className={classes.modal}>
                <span className={classes.modalTitle}>
                    {title === 'External ID' ? 'Adding' : 'Updating'} {title}
                </span>
                <div className={classes.modalContent}>{children}</div>
                <div className={classes.btnContainer}>
                    <Button
                        className={classes.btn}
                        onClick={() => closeModalClick(false)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Fade>
    );
};

export default UpdateModal;
