import { Cookies } from 'react-cookie';

class AuthManager {
    private static myInstance: AuthManager | null = null;
    private myCookies: Cookies;

    private constructor() {
        this.myCookies = new Cookies();
    }

    /**
     * @returns {AuthManager}
     */
    public static getInstance(): AuthManager {
        if (AuthManager.myInstance === null) {
            AuthManager.myInstance = new AuthManager();
        }
        return AuthManager.myInstance;
    }

    public getApiToken(): string | undefined {
        const token = this.myCookies.get('API_TOKEN');

        if (!token) {
            console.warn(
                'API_TOKEN not found. Ensure the user is logged in and the cookie is properly set.'
            );
        }

        return token || undefined;
    }

    public getDeploymentOrgIds(): string[] | undefined {
        return this.myCookies.get('DEPLOYMENT_ORGANIZATION_IDS');
    }

    public getIntakeSteps(): string[] {
        return this.myCookies.get('INTAKE_STEPS');
    }

    public getToken(): string | undefined {
        return this.myCookies.get('SESSION_TOKEN');
    }

    public getUserDeploymentId(): string | undefined {
        return this.myCookies.get('USER_DEPLOYMENT_ID');
    }

    public getUserDeploymentIds(): string[] | undefined {
        return this.myCookies.get('DEPLOYMENT_IDS');
    }

    public getUserDeploymentName(): string | undefined {
        return this.myCookies.get('USER_DEPLOYMENT_NAME');
    }

    public getUserID(): string | undefined {
        return this.myCookies.get('USER');
    }

    public getUserName(): string | undefined {
        return this.myCookies.get('USER_NAME');
    }

    public getUserOrganizationId(): string | undefined {
        return this.myCookies.get('USER_ORGANIZATION_ID');
    }

    public getUserOrganizationName(): string | undefined {
        return this.myCookies.get('USER_ORGANIZATION_NAME');
    }

    public setApiToken(token: string): void {
        document.cookie = 'API_TOKEN=; Max-Age=0; path=/holders';

        // TODO: http only cookie and signature keeper
        this.myCookies.set('API_TOKEN', token, {
            path: '/'
        });
    }

    public setDeploymentOrgIds(ids: string[]): void {
        this.myCookies.set('DEPLOYMENT_ORGANIZATION_IDS', ids);
    }

    public setIntakeSteps(steps: string[]): void {
        this.myCookies.set('INTAKE_STEPS', steps);
    }

    public setToken(token: string): void {
        // TODO: http only cookie and signature keeper
        this.myCookies.set('SESSION_TOKEN', token);
    }

    public setUserDeploymentId(id: string): void {
        this.myCookies.set('USER_DEPLOYMENT_ID', id);
    }

    public setUserDeploymentIds(ids: string[]): void {
        this.myCookies.set('DEPLOYMENT_IDS', ids);
    }

    public setUserDeploymentName(name: string): void {
        // TODO: http only cookie and signature keeper
        this.myCookies.set('USER_DEPLOYMENT_NAME', name);
    }

    public setUserID(id: string): void {
        this.myCookies.set('USER', id);
    }

    public setUserName(name: string): void {
        this.myCookies.set('USER_NAME', name);
    }

    public setUserOrganizationId(id: string): void {
        // TODO: http only cookie and signature keeper
        this.myCookies.set('USER_ORGANIZATION_ID', id);
    }

    public setUserOrganizationName(name: string): void {
        // TODO: http only cookie and signature keeper
        this.myCookies.set('USER_ORGANIZATION_NAME', name);
    }

    public unsetToken(): void {
        this.myCookies.remove('API_TOKEN');
        this.myCookies.remove('DEPLOYMENT_IDS');
        this.myCookies.remove('DEPLOYMENT_ORGANIZATION_IDS');
        this.myCookies.remove('INTAKE_STEPS');
        this.myCookies.remove('SESSION_TOKEN');
        this.myCookies.remove('USER');
        this.myCookies.remove('USER_DEPLOYMENT_ID');
        this.myCookies.remove('USER_DEPLOYMENT_NAME');
        this.myCookies.remove('USER_ORGANIZATION_ID');
        this.myCookies.remove('USER_ORGANIZATION_NAME');
        this.myCookies.remove('USER_NAME');
    }
}

export default AuthManager;
