import SamaritanFetch from './httpClient';

import AuthManager from '../auth';

export const createMemberCaseload = (memberID: number) => {
    const memberCaseload = {
        ctp_caseload: {
            homeless_id: memberID,
            status: 'active'
        }
    };

    return SamaritanFetch.v3.post('ctp_caseloads', {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(memberCaseload)
    });
};

export const fetchHomelesses = () => {
    const auth = AuthManager.getInstance();
    interface Query {
        samaritan_members: {
            profile_stage: string[];
            deployment_id?: string | string[];
        };
    }

    const query: Query = {
        samaritan_members: {
            profile_stage: [
                'EXITED',
                'LIVE',
                'NEEDS_REVIEW',
                'PENDING',
                'UNSUCCESSFUL_PENDING'
            ]
        }
    };

    if (auth.getUserDeploymentIds()) {
        const deploymentOrgIds = auth.getUserDeploymentIds();

        query.samaritan_members = {
            ...query.samaritan_members,
            deployment_id: deploymentOrgIds
        };
    } else {
        query.samaritan_members.deployment_id = [];
    }

    return SamaritanFetch.v3('/samaritan_members', { query });
};

export const fetchHomelessLanguagePreferences = () => {
    return SamaritanFetch.v3('/samaritan_members/language_preferences');
};

export const fetchUserCaseloads = () => {
    return SamaritanFetch.v3('ctp_caseloads', {
        method: 'GET'
    });
};

export const removeMemberFromMyMembers = (caseloadID: number) => {
    const cancelledStatus = {
        ctp_caseload: {
            status: 'cancelled'
        }
    };

    return SamaritanFetch.v3.put(`ctp_caseloads/${caseloadID}`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(cancelledStatus)
    });
};

export const saveMemberToMyMembers = (caseloadID: number) => {
    const activeStatus = {
        ctp_caseload: {
            status: 'active'
        }
    };

    return SamaritanFetch.v3.put(`ctp_caseloads/${caseloadID}`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(activeStatus)
    });
};

export const updateUserLanguagePreference = (
    memberID: number,
    language: string
) => {
    return SamaritanFetch.v3.put(`/samaritan_members/${memberID}`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            samaritan_member: {
                language_preference: language
            }
        })
    });
};