import { connect } from 'react-redux';
import App from '../components/app';

import { fetchUser } from '../redux/actions/user';

// state is object that holds reducers from combineReducers and hence the state in them
function mapStateToProps(state: any) {
    const { getApiError, getApiErrorResponse, unauthorizedUser } = state.error;
    const { email, id, last_name, name } = state.user.user;

    return {
        email,
        getApiError,
        getApiErrorResponse,
        id,
        last_name,
        name,
        unauthorizedUser
    };
}

const mapDispatchToProps = {
    fetchUser
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
