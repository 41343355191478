import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    notesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        marginTop: '16px'
    },
    note: {
        fontWeight: 300,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#F1F1F1'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#9897E3',
            borderRadius: '8px'
        }
    },
    title: {
        fontWeight: 600,
        '& i': {
            marginRight: '8px'
        }
    }
}));

type NotesProps = {
    note: string;
};

const Notes: React.FC<NotesProps> = ({ note }) => {
    const classes = useStyles();

    return (
        <div className={classes.notesContainer}>
            <span className={classes.title}>
                <i className="far fa-sticky-note" /> Additional Notes:
            </span>
            <span className={classes.note}>
                {note ? note : 'No additional notes'}
            </span>
        </div>
    );
};

export default Notes;
