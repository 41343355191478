import React, { useState } from 'react';

import Card from '../components/Card';
import InfoCard from '../components/InfoCard';
import ProgressBar from './components/ProgressBar';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    bonusTrackerContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        color: '#1F1F51',
        '&:active': {
            transform: 'scale(0.98)'
        }
    },
    trackerBody: {
        position: 'relative',
        top: '-32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        padding: '60px 0 20px 0 !important'
    },
    monthTitle: {
        fontSize: '24px',
        fontWeight: 800,
        lineHeight: '33px'
    }
}));

type BonusTrackerProps = {
    assignedBonusAmount: number;
    holder: any;
    maxBonusAmount: number;
    remainingBonusAmount: number;
};

const BonusTracker: React.FC<BonusTrackerProps> = ({
    assignedBonusAmount,
    holder,
    maxBonusAmount,
    remainingBonusAmount
}) => {
    const classes = useStyles();

    const [showTracker, setShowTracker] = useState(true);

    const month = new Date().toLocaleString('default', { month: 'long' });

    const cardStyle = {
        position: 'relative',
        backgroundColor: '#E7EFFE',
        zIndex: '1'
    };

    return (
        <div className={classes.bonusTrackerContainer}>
            <Card
                style={cardStyle as any}
                onClick={() => setShowTracker(!showTracker)}
            >
                <InfoCard open={showTracker} type="bonus" />
            </Card>
            {showTracker && (
                <Card
                    className={classes.trackerBody}
                    cardStyle={
                        remainingBonusAmount > 0
                            ? { height: '298px' }
                            : { height: '362px' }
                    }
                >
                    <span
                        className={classes.monthTitle}
                    >{`${month} Bonus Pool`}</span>
                    <ProgressBar
                        amount={assignedBonusAmount}
                        maxAmount={maxBonusAmount}
                        name={holder.name}
                        remainingAmount={remainingBonusAmount}
                    />
                </Card>
            )}
        </div>
    );
};

export default BonusTracker;
