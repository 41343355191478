import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Caseload, Homeless, Referral, User } from 'types';

import AddButton from '../components/AddButton';
import Card from '../components/Card';
import ReferralCard from 'components/referrals/components/ReferralCard';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    referralsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },
    referralList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    selector: {
        display: 'flex'
    },
    tab: {
        borderRadius: '16px',
        border: '1px solid #062D7A',
        color: '#062D7A',
        flex: 1,
        fontSize: '16px',
        textTransform: 'none',
        '&.Mui-selected': {
            backgroundColor: '#062D7A',
            color: '#FFFFFF'
        }
    }
}));

type ReferralsProps = {
    acceptReferral: (referralID: number) => void;
    caseloads: Caseload[];
    holder: Homeless;
    memberReferrals: Referral[];
    name: string;
    rejectReferral: (referralID: number) => void;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (
        caseloadID: number,
        memberID: number,
        accepted?: boolean
    ) => void;
    setReferMemberPopUp: (value: boolean) => void;
    user: User;
};

const Referrals: React.FC<ReferralsProps> = ({
    acceptReferral,
    caseloads,
    holder,
    memberReferrals,
    name,
    rejectReferral,
    removeMemberFromMyMembers,
    saveMemberToMyMembers,
    setReferMemberPopUp,
    user
}) => {
    const classes = useStyles();
    const isFirstRenderRef = useRef(true);
    const referralListRef = useRef<HTMLDivElement>(null);

    const { id: userID } = user;

    const myReferrals = memberReferrals.filter(
        (referral) =>
            referral.referrer_id === userID || referral.referee_id === userID
    );

    const [alignment, setAlignment] = useState('all');

    // fix this?
    const direction = alignment === 'all' ? 'outgoing' : 'incoming';

    const referralsToRender = useMemo(() => {
        return alignment === 'all' ? memberReferrals || [] : myReferrals || [];
    }, [alignment, memberReferrals, myReferrals]);

    const handleChange = useCallback(
        (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
            setAlignment(newAlignment);
        },
        []
    );

    const matchHomelessToCaseload = (referral: Referral) => {
        const caseload = caseloads.find(
            (caseload) => caseload.homeless_id === referral.homeless_id
        );

        if (!caseload) {
            return null;
        }

        return caseload;
    };

    const openReferMemberPopUp = useCallback(() => {
        setReferMemberPopUp(true);
    }, [setReferMemberPopUp]);

    useEffect(() => {
        if (referralListRef.current && !isFirstRenderRef.current) {
            referralListRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            isFirstRenderRef.current = false;
        }
    }, [alignment]);

    return (
        <div className={classes.referralsContainer} ref={referralListRef}>
            <Card info name={name} referrals />
            <ToggleButtonGroup
                className={classes.selector}
                aria-label="Referrals"
                exclusive
                onChange={handleChange}
                value={alignment}
            >
                <ToggleButton className={classes.tab} value="all">
                    All Referrals
                </ToggleButton>
                <ToggleButton className={classes.tab} value="my">
                    My Referrals
                    {myReferrals.length > 0 && ` (${myReferrals.length})`}
                </ToggleButton>
            </ToggleButtonGroup>
            {referralsToRender.length > 0 && (
                <div className={classes.referralList}>
                    {referralsToRender.map((referral) => (
                        <ReferralCard
                            key={referral.id}
                            acceptReferral={acceptReferral}
                            caseload={
                                matchHomelessToCaseload(referral) as Caseload
                            }
                            direction={direction}
                            homeless={holder}
                            profile
                            referral={referral}
                            rejectReferral={rejectReferral}
                            removeMemberFromMyMembers={
                                removeMemberFromMyMembers
                            }
                            saveMemberToMyMembers={saveMemberToMyMembers}
                            user={user}
                        />
                    ))}
                </div>
            )}
            <AddButton action={openReferMemberPopUp} type="referral" />
        </div>
    );
};

export default Referrals;
