import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { resetIntake } from 'redux/actions/intake';

import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    buttonBase: {
        borderRadius: '8px',
        padding: 0,
        width: '100%',
        textAlign: 'left'
    },
    listItemText: {
        borderRadius: '8px',
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '20px',
        padding: '18px 0',
        textDecoration: 'none',
        whiteSpace: 'pre-wrap',
        width: '100%',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.08)'
        },
        '& > i': {
            fontSize: '20px',
            margin: '0 15px'
        },
        '&.active': {
            backgroundColor: '#7378E8',
            color: 'white',
            padding: '18px 0',
            textDecoration: 'none',
            transition: 'all 0.3s ease-in-out'
        }
    }
}));

type NavItemProps = {
    to: string;
    title: string;
    newTab?: boolean;
};

export default function NavItem({ to, title, newTab = false }: NavItemProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const navIcons = {
        'Client List': 'far fa-users',
        FAQ: 'far fa-question-circle',
        Logout: 'far fa-sign-out',
        'Member Intake': 'far fa-user-plus',
        'Your Referrals': 'far fa-user-friends',
        Settings: 'far fa-cog'
    } as Record<string, string>;

    const handleGAClick = (title: string) => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Click',
            label: title
        });

        if (title === 'Member Intake') {
            dispatch(resetIntake());
        }
    };

    return (
        <ButtonBase className={classes.buttonBase}>
            <NavLink
                to={{ pathname: to }}
                target={newTab ? '_blank' : undefined}
                className={classes.listItemText}
                activeClassName={`${classes.listItemText} active`}
                isActive={(match, location) => {
                    if (!match) {
                        if (location.pathname === '/') {
                            return title === 'Client List';
                        }
                        return false;
                    }

                    return true;
                }}
                onClick={() => handleGAClick(title)}
            >
                <i className={`${navIcons[title]}`} />
                {title}
            </NavLink>
        </ButtonBase>
    );
}
