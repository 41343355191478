import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import { useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { ActionStepTemplate, Goals, NewGoalActions } from 'types';

import ActionStepCard from './ActionStepCard';
import ActionStepInput from './ActionStepInput';
import ActionTemplate from './ActionTemplate';
import Card from 'components/_shared/Card';
import Overlay from 'components/_shared/Overlay';
import { DoneButton } from 'components/_shared/buttons';

import { newGoalThunk } from 'redux/actions/goals';

import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    cancelBtn: {
        fontFamily: 'Manrope',
        fontWeight: '700',
        fontSize: '1.25rem',
        color: '#8185D6',
        paddingTop: '15px',
        background: 'transparent',
        margin: '0'
    },
    cardTitle: {
        position: 'absolute',
        top: '-1rem',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#8882D8',
        borderRadius: '100px',
        color: '#fff',
        fontWeight: '800',
        letterSpacing: '0.1em',
        lineHeight: '100%',
        padding: '8px 20px',
        width: 'fit-content',
        textTransform: 'uppercase'
    },
    fadeContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
        width: '100%'
    },
    inputFields: {
        height: '3.125rem',
        width: '100%',
        borderRadius: '40px',
        fontFamily: 'Manrope',
        fontSize: '1.75rem',
        fontWeight: '600',
        color: '#1f1f51',
        letterSpacing: '-0.21px',
        background: 'transparent',
        paddingLeft: '15px',
        resize: 'none',
        '&::placeholder': {
            textAlign: 'center',
            color: '#A8A8BF',
            fontSize: '1.25rem'
        },
        '&:focus::placeholder': {
            textAlign: 'left',
            fontStyle: 'italic',
            fontWeight: '500'
        },
        '&:focus': {
            outline: 'none',
            border: '0 !important',
            textAlign: 'left',
            padding: '4px 8px',
            textDecoration: 'underline',
            textDecorationColor: '#E3E4FA',
            textDecorationThickness: '4px'
        }
    },
    needWrapper: {
        position: 'relative',
        borderRadius: '20px',
        width: '90%',
        padding: '20px',
        margin: '20px 0 80px 0',
        '& button': {
            cursor: 'pointer'
        },
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        '@media (max-width: 768px)': {
            maxWidth: '300px'
        }
    },
    submitBtn: {
        fontFamily: 'Manrope',
        fontWeight: '800',
        fontSize: '1.25rem',
        color: '#fff',
        width: '50%',
        background: 'linear-gradient(90deg, #9897E3 0%, #B8C2FF 100%)',
        padding: '15px 0',
        margin: '0'
    },
    submitBtnContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px'
    },
    writeBtn: {
        fontFamily: 'Manrope',
        fontWeight: '800',
        fontSize: '16px',
        padding: '15px 20px',
        margin: 'auto'
    },
    '@global': {
        '#action-template-card, #need-div': {
            margin: '0 !important'
        }
    }
}));

type ActionStepFormProps = {
    actionSuggestions: any;
    addFailed?: boolean;
    editAction?: number[];
    editForm?: {
        actionIndex?: number;
        costInputState: number;
        customGoalInputState?: string;
        dateInputState: number | string;
        descriptionInput: boolean;
        descriptionInputState: string;
        goalInputState?: Goals[];
        qolMeasureState?: number;
    };
    failedAction?: NewGoalActions | null;
    from: string;
    holderID?: number;
    intakeGoals?: any[];
    onClick?: () => void;
    ref?: any;
    remainingBonusAmount: number;
    setEditAction?: (value: number[]) => void;
    setEditNeed?: (value: number[]) => void;
    setFormComplete?: (value: boolean) => void;
    setGuide?: (value: boolean) => void;
    setIntakeNeeds?: (value: any[]) => void;
    setIntakeGoals?: (value: any[]) => void;
    setShowEdit?: (value: boolean) => void;
    setShowForm: (value: boolean) => void;
    setTemporaryAction?: (temporaryAction: any) => void;
    showEdit?: boolean;
    showForm?: boolean;
    templateActions?: ActionStepTemplate[] | null;
    temporaryAction?: any;
};

const calculateDate = (due_at: number | string) => {
    if (typeof due_at === 'string' && due_at <= new Date().toISOString()) {
        return new Date(`${due_at}T00:00:00`).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    const parseDateString = (dateStr: string): Date | null => {
        let date;

        if (dateStr.includes('T')) {
            date = new Date(dateStr);
        } else {
            date = new Date(dateStr + 'T00:00:00');
        }

        return isNaN(date.getTime()) ? null : date;
    };

    const calculateDaysDifference = (date1: Date, date2: Date): number => {
        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    if (typeof due_at === 'string') {
        const dueDate = parseDateString(due_at);
        if (!dueDate) return 'Invalid date format';

        const today = new Date();
        const diffDays = calculateDaysDifference(today, dueDate);

        if (diffDays <= 3) {
            return 'ASAP';
        } else if (diffDays === 30) {
            return '1 month';
        } else {
            return `${diffDays} Days`;
        }
    } else if (typeof due_at === 'number') {
        if (due_at === 0 || !due_at) {
            return 'No due date';
        } else if (due_at === 30) {
            return '1 month';
        } else {
            return `${due_at} days`;
        }
    } else {
        return 'Invalid input';
    }
};

const ActionStepForm: React.FC<ActionStepFormProps> = forwardRef(
    (
        {
            actionSuggestions,
            addFailed,
            editAction,
            editForm,
            failedAction,
            from,
            holderID,
            intakeGoals,
            remainingBonusAmount,
            showEdit,
            setEditAction,
            setFormComplete,
            setGuide,
            setIntakeGoals,
            setShowForm,
            setTemporaryAction,
            showForm,
            templateActions,
            temporaryAction
        },
        ref
    ) => {
        const classes = useStyles();
        const dispatch = useDispatch();

        const cardSpring = useSpring({
            config: { tension: 80, friction: 15 },
            delay: temporaryAction ? 1000 : 0,
            from: {
                opacity: temporaryAction ? 1 : 1,
                transform: 'translateY(0px)'
            },
            to: {
                opacity: temporaryAction ? 1 : 1,
                transform: temporaryAction
                    ? 'translateY(-137px)'
                    : 'translateY(0px)'
            },
            onRest: setTemporaryAction
                ? () => setTemporaryAction(null)
                : () => {}
        });

        const cardStaticStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '400px',
            width: '100%'
        };

        const [cost, setCost] = useState<null | number>(null);
        const [costInput, setCostInput] = useState(false);
        const [date, setDate] = useState<any>(``);
        const [dateInput, setDateInput] = useState(false);
        const [dateSuggestion, setDateSuggestion] = useState(``);
        const [description, setDescription] = useState(``);
        const [descriptionInput, setDescriptionInput] = useState(false);
        const [displayCostBtns, setDisplayCostBtns] = useState(true);
        const [displayDateBtns, setDisplayDateBtns] = useState(true);
        const [displayDescriptionBtns, setDisplayDescriptionBtns] =
            useState(true);

        const [displayCustomDateInput, setDisplayCustomDateInput] =
            useState(false);
        const [displayEditCostBtns, setDisplayEditCostBtns] = useState(false);
        const [displayEditDateBtns, setDisplayEditDateBtns] = useState(false);
        const [displayEditDescriptionBtns, setDisplayEditDescriptionBtns] =
            useState(false);

        const [isEditing, setIsEditing] = useState({
            cost: false,
            due_dates: false,
            descriptions: false
        });
        const [overlay, setOverlay] = useState(false);
        const [qolMeasure, setQolMeasure] = useState<null | number>(null);
        const [useCustom, setUseCustom] = useState(false);
        const [useTemplate, setUseTemplate] = useState(false);

        const removeEditActionType = useCallback(
            (actionTypeIndex: number) => {
                setEditAction!(
                    editAction!.filter((action) => action !== actionTypeIndex)
                );
            },
            [editAction, setEditAction]
        );

        const handleCardClick = (e: {
            target: { tagName: string; id: string };
        }) => {
            if (
                e.target.tagName !== `INPUT` &&
                e.target.tagName !== `BUTTON` &&
                e.target.tagName !== `TEXTAREA` &&
                e.target.id !== 'edit-button'
            ) {
                hideBtns();

                setIsEditing({
                    cost: false,
                    due_dates: false,
                    descriptions: false
                });
            }

            const needDiv = document.getElementById(`need-div`);

            if (needDiv) {
                needDiv.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        };

        const handleAddForIntake = useCallback(() => {
            if (typeof date === 'number') {
                const today = new Date();
                today.setDate(today.getDate() + date);
                setDate(today.toISOString().split('T')[0]);
            }

            const asFormData = {
                amount: cost,
                description: description,
                due_at: date + 'T12:00:00-07:00',
                quality_of_life_measure_id: qolMeasure
            };

            setOverlay(true);

            const cardTimer = setTimeout(
                () => {
                    if (!temporaryAction && setTemporaryAction) {
                        setTemporaryAction(asFormData);
                    }
                },
                useCustom ? 1500 : 1000
            );

            setTimeout(
                () => {
                    if (!showEdit) {
                        setIntakeGoals!([...intakeGoals!, asFormData]);
                        setShowForm(false);
                    } else {
                        intakeGoals![editForm!.actionIndex!] = {
                            amount: cost,
                            description: description,
                            due_at: date,
                            quality_of_life_measure_id: qolMeasure
                        };

                        setIntakeGoals!(intakeGoals!);
                        removeEditActionType(editForm!.actionIndex!);
                    }

                    return clearTimeout(cardTimer);
                },
                useCustom ? 3000 : 0
            );
        }, [
            cost,
            date,
            description,
            editForm,
            intakeGoals,
            qolMeasure,
            removeEditActionType,
            setIntakeGoals,
            setShowForm,
            setTemporaryAction,
            showEdit,
            temporaryAction,
            useCustom
        ]);

        const handleAddForProfile = useCallback(() => {
            if (holderID && (cost || cost === 0)) {
                dispatch(
                    newGoalThunk(
                        holderID!,
                        description,
                        date,
                        cost,
                        null,
                        qolMeasure
                    )
                );
            }

            setShowForm(false);
        }, [
            cost,
            date,
            description,
            dispatch,
            holderID,
            qolMeasure,
            setShowForm
        ]);

        const hideBtns = () => {
            const conditions = [
                {
                    display: displayCostBtns,
                    condition: typeof cost === 'string' && cost > 0,
                    setter: setDisplayCostBtns
                },
                {
                    display: displayDateBtns,
                    condition: date.length,
                    setter: setDisplayDateBtns
                },
                {
                    display: displayDescriptionBtns,
                    condition: description.length,
                    setter: setDisplayDescriptionBtns
                },
                {
                    display: displayEditCostBtns,
                    condition: typeof cost === 'string' && cost > 0,
                    setter: setDisplayEditCostBtns
                },
                {
                    display: displayEditDateBtns,
                    condition: date.length,
                    setter: setDisplayEditDateBtns
                },
                {
                    display: displayEditDescriptionBtns,
                    condition: true,
                    setter: setDisplayEditDescriptionBtns
                }
            ];

            conditions.forEach(({ display, condition, setter }) => {
                if (display && condition) {
                    setter(false);
                }
            });
        };

        const actionTemplateProps = {
            from,
            remainingBonusAmount,
            setCost,
            setCostInput,
            setDate,
            setDateInput,
            setDateSuggestion,
            setDescription,
            setDescriptionInput,
            setDisplayCostBtns,
            setDisplayDateBtns,
            setDisplayDescriptionBtns,
            setQolMeasure,
            setShowForm,
            setTemporaryAction,
            setUseCustom,
            setUseTemplate,
            templates: templateActions!,
            temporaryAction
        };

        useEffect(() => {
            if (showEdit) {
                setCost(editForm!.costInputState);
                setDate(editForm!.dateInputState);
                setDateSuggestion(calculateDate(editForm!.dateInputState));
                setDescription(editForm!.descriptionInputState);

                if (editForm!.qolMeasureState) {
                    setQolMeasure(editForm!.qolMeasureState);
                }
            }
        }, [showEdit, editForm]);

        useEffect(() => {
            if (
                cost &&
                date &&
                description &&
                useTemplate &&
                from === 'intake'
            ) {
                handleAddForIntake();
            }
        }, [cost, date, description, from, handleAddForIntake, useTemplate]);

        useEffect(() => {
            if (
                (cost || cost === 0) &&
                date &&
                description &&
                from === 'profile' &&
                setFormComplete
            ) {
                setFormComplete(true);
            }
        }, [cost, date, description, from, setFormComplete]);

        useEffect(() => {
            if (addFailed && failedAction) {
                const {
                    due_at,
                    goal,
                    quality_of_life_measure_id,
                    stake_match_amount
                } = failedAction.data;

                setCost(Number(stake_match_amount));
                setCostInput(true);
                setDate(due_at);
                setDateSuggestion(calculateDate(due_at));
                setDateInput(true);
                setDescription(goal);
                setDescriptionInput(true);
                setDisplayCostBtns(false);
                setDisplayDateBtns(false);
                setDisplayDescriptionBtns(false);
                setQolMeasure(quality_of_life_measure_id);
            }
        }, [addFailed, failedAction]);

        useImperativeHandle(ref, () => ({
            handleAddForProfile
        }));

        return (
            <>
                {temporaryAction && (
                    <animated.div style={{ ...cardSpring, ...cardStaticStyle }}>
                        <Fade in={temporaryAction !== null} timeout={800}>
                            <div className={classes.fadeContainer}>
                                <ActionStepCard
                                    action={temporaryAction}
                                    remainingBonusAmount={remainingBonusAmount}
                                />
                            </div>
                        </Fade>
                    </animated.div>
                )}
                {!temporaryAction && (
                    <div className={classes.fadeContainer}>
                        {!cost &&
                        !date &&
                        !description.length &&
                        templateActions &&
                        templateActions.length ? (
                            <ActionTemplate {...actionTemplateProps} />
                        ) : (
                            <Fade in={!showForm} timeout={500}>
                                <div className={classes.fadeContainer}>
                                    <Card
                                        className={classes.needWrapper}
                                        id="need-div"
                                        onClick={(e) => handleCardClick(e)}
                                        style={{ margin: '0px !important' }}
                                    >
                                        {overlay && (
                                            <Overlay text="Action Step Added!" />
                                        )}
                                        {!showEdit && (
                                            <span className={classes.cardTitle}>
                                                CREATE ACTION STEP
                                            </span>
                                        )}
                                        <ActionStepInput
                                            needType={`descriptions`}
                                            inputState={
                                                !showEdit
                                                    ? descriptionInput
                                                    : true
                                            }
                                            setInputState={setDescriptionInput}
                                            inputValState={description}
                                            setInputValState={setDescription}
                                            displayBtnState={
                                                showEdit
                                                    ? displayEditDescriptionBtns
                                                    : displayDescriptionBtns
                                            }
                                            setDisplayBtnState={
                                                showEdit
                                                    ? setDisplayEditDescriptionBtns
                                                    : setDisplayDescriptionBtns
                                            }
                                            showEdit={showEdit!}
                                            actionSuggestions={
                                                actionSuggestions
                                            }
                                            setGuide={setGuide!}
                                            intakeGoals={intakeGoals}
                                            isEditing={isEditing}
                                            setIsEditing={setIsEditing}
                                        />
                                        {description.length > 0 || showEdit ? (
                                            <ActionStepInput
                                                needType={`cost`}
                                                inputState={
                                                    !showEdit ? costInput : true
                                                }
                                                setInputState={setCostInput}
                                                inputValState={cost}
                                                setInputValState={setCost}
                                                displayBtnState={
                                                    showEdit
                                                        ? displayEditCostBtns
                                                        : displayCostBtns
                                                }
                                                setDisplayBtnState={
                                                    showEdit
                                                        ? setDisplayEditCostBtns
                                                        : setDisplayCostBtns
                                                }
                                                actionSuggestions={
                                                    actionSuggestions
                                                }
                                                setGuide={setGuide!}
                                                remainingBonusAmount={
                                                    remainingBonusAmount
                                                }
                                                showEdit={showEdit!}
                                                isEditing={isEditing}
                                                setIsEditing={setIsEditing}
                                            />
                                        ) : null}
                                        {cost || cost === 0 || showEdit ? (
                                            <ActionStepInput
                                                needType={`due_dates`}
                                                inputState={
                                                    !showEdit ? dateInput : true
                                                }
                                                setInputState={setDateInput}
                                                inputValState={date}
                                                setInputValState={setDate}
                                                displayBtnState={
                                                    showEdit
                                                        ? displayEditDateBtns
                                                        : displayDateBtns
                                                }
                                                setDisplayBtnState={
                                                    showEdit
                                                        ? setDisplayEditDateBtns
                                                        : setDisplayDateBtns
                                                }
                                                actionSuggestions={
                                                    actionSuggestions
                                                }
                                                displayDateState={
                                                    dateSuggestion
                                                }
                                                setDisplayDateState={
                                                    setDateSuggestion
                                                }
                                                displayCustomDateInput={
                                                    displayCustomDateInput
                                                }
                                                setDisplayCustomDateInput={
                                                    setDisplayCustomDateInput
                                                }
                                                setGuide={setGuide!}
                                                saveAction={
                                                    from === 'intake'
                                                        ? handleAddForIntake
                                                        : () => {}
                                                }
                                                showEdit={showEdit!}
                                                isEditing={isEditing}
                                                setIsEditing={setIsEditing}
                                            />
                                        ) : null}
                                        {showEdit ||
                                        (useTemplate &&
                                            cost &&
                                            date &&
                                            description &&
                                            from === 'intake') ? (
                                            <div
                                                className={
                                                    classes.submitBtnContainer
                                                }
                                            >
                                                <DoneButton
                                                    handleClick={
                                                        handleAddForIntake
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </Card>
                                </div>
                            </Fade>
                        )}
                    </div>
                )}
            </>
        );
    }
);

export default ActionStepForm;
