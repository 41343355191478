import CONSTANTS from '../constants/constants';

export function handleSamaritanApiError(
    error: { message: string },
    _dispatch?: Function
) {
    return (dispatch: Function) => {
        if (String(error.message) !== '401') {
            dispatch(getApiError(error));
        }
    };
}

export function resetAllState() {
    return {
        type: CONSTANTS.RESET
    };
}

export function getApiError(response: object) {
    return {
        type: CONSTANTS.GET_API_ERROR,
        payload: response
    };
}

export function resetApiError() {
    return {
        type: CONSTANTS.RESET_API_ERROR
    };
}

export function foundUnauthorizedUser() {
    return {
        type: CONSTANTS.UNAUTHORIZED_USER
    };
}

export function forbiddenError(error_messages: object) {
    return {
        type: CONSTANTS.SET_INTAKE_ERROR,
        payload: error_messages
    };
}

export function resetUnauthorized() {
    return {
        type: CONSTANTS.RESET_UNAUTHORIZED
    };
}
