import ReactGA from 'react-ga4';

import Border from 'assets/border-add-lg.svg';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        addButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            padding: '50px 0',
            backgroundImage: `url(${Border})`,
            backgroundColor: '#FBFBFF',
            borderRadius: '32px',
            color: '#9A9EE7',
            fontSize: '22px',
            fontWeight: 800,
            cursor: 'pointer',
            textTransform: 'uppercase',
            width: '100%',
            '&:active': {
                transform: 'scale(0.98)'
            },
            '& i': {
                marginRight: '10px',
                fontSize: '14px',
                color: '#FFFFFF',
                background:
                    'linear-gradient(0deg, #9A9EE7, #9A9EE7), linear-gradient(180deg, rgba(204, 211, 255, 0.4) 0%, rgba(119, 113, 180, 0.4) 100%)',
                boxShadow:
                    '-3.848889919054959e-16px 6.285714149475098px 31.428569793701172px 0px #0000001F',
                borderRadius: '50%',
                padding: '8px 10px',
                animation: '$pulse 2.5s infinite'
            }
        },
        '@keyframes pulse': {
            '0%': {
                boxShadow: '0 0 0 0 rgba(154, 158, 231, 0.4)'
            },
            '70%': {
                boxShadow: '0 0 0 10px rgba(154, 158, 231, 0)'
            },
            '100%': {
                boxShadow: '0 0 0 0 rgba(154, 158, 231, 0)'
            }
        },
        tooltipContainer: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FEF3E7',
            borderRadius: '20px 20px 40px 40px',
            color: '#7A4406',
            width: '100%'
        },
        bonusTooltip: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '16px 32px 24px 32px'
        },
        tooltipHeader: {
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 800,
            '& i': {
                marginRight: '8px'
            }
        },
        tooltipBody: {
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 400
        }
    })
);

type Props = {
    action: (value: boolean) => void;
    remainingBonusAmount?: number;
    style?: React.CSSProperties;
    type: string;
};

const AddButton: React.FC<Props> = ({
    action,
    remainingBonusAmount,
    style,
    type
}) => {
    const classes = useStyles();

    const shouldShowTooltip =
        type === 'action step' && remainingBonusAmount === 0;

    const getAssignedBonusMessage = () => {
        const today = new Date();
        const currentMonth = today.toLocaleString('default', {
            month: 'long'
        });

        const nextMonth = new Date(today);
        nextMonth.setMonth(today.getMonth() + 1);

        if (nextMonth.getMonth() !== (today.getMonth() + 1) % 12) {
            nextMonth.setFullYear(today.getFullYear(), today.getMonth() + 1);
        }

        const nextMonthName = nextMonth.toLocaleString('default', {
            month: 'long'
        });

        return (
            `You've assigned all of ${currentMonth}'s monthly bonus pool. ` +
            `You'll be able to assign bonuses again at the start of ${nextMonthName}.`
        );
    };

    const handleAddButtonClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Add Button Click',
            label: type
        });

        action(true);
    };

    const renderButtonContent = () => {
        switch (type) {
            case 'confirm':
                return (
                    <>
                        <i className="fas fa-sign-out" />
                        <span>Confirm Membership</span>
                    </>
                );
            case 'context':
                return (
                    <>
                        <i className="fas fa-plus" />
                        <span>Tell Us More...</span>
                    </>
                );
            case 'facts':
                return (
                    <>
                        <i className="fas fa-plus" />
                        <span>Add New Answer</span>
                    </>
                );
            case 'reenroll':
                return (
                    <>
                        <i className="fas fa-sign-out" />
                        <span>Re-Enroll</span>
                    </>
                );
            case 'referral':
                return (
                    <>
                        <i className="fas fa-plus" />
                        <span>Create New Referral</span>
                    </>
                );
            case 'unenroll':
                return (
                    <>
                        <i className="fas fa-sign-out" />
                        <span>Unenroll</span>
                    </>
                );
            default:
                return (
                    <>
                        <i className="fas fa-plus" />
                        <span>Add {type}</span>
                    </>
                );
        }
    };

    return (
        <div
            className={
                shouldShowTooltip ? classes.tooltipContainer : 'add-button'
            }
            style={!shouldShowTooltip ? { display: 'flex' } : {}}
        >
            {shouldShowTooltip && (
                <div className={classes.bonusTooltip}>
                    <span className={classes.tooltipHeader}>
                        <i className="fas fa-info-circle" /> Heads Up!
                    </span>
                    <span className={classes.tooltipBody}>
                        {getAssignedBonusMessage()}
                    </span>
                </div>
            )}
            <div
                className={classes.addButtonContainer}
                onClick={handleAddButtonClick}
                style={style}
            >
                {renderButtonContent()}
            </div>
        </div>
    );
};

export default AddButton;
