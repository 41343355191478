import { formattedDate } from 'util/index';

import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        bodyItem: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '4px 0'
        },
        bodyItemTitle: {
            fontWeight: 700
        },
        bodyItemValue: {
            fontWeight: 300
        }
    })
);

type UserReferralProps = {
    name: string;
    org: string;
    date: string;
};

const UserReferral: React.FC<UserReferralProps> = ({ name, org, date }) => {
    const classes = useStyles();

    const renderDetail = (title: string, value: string | Date) => (
        <div className={classes.bodyItem}>
            <span className={classes.bodyItemTitle}>{title}:</span>
            <span className={classes.bodyItemValue}>
                {value instanceof Date ? formattedDate(value) : value}
            </span>
        </div>
    );

    return (
        <>
            {renderDetail('Referrer', name)}
            {renderDetail('Organization', org)}
            {renderDetail('Referral Date', new Date(date))}
        </>
    );
};

export default UserReferral;
