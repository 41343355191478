import classNames from 'classnames';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    accepted: {
        backgroundColor: '#E9FBF2',
        border: '0.2px solid #1B975780',
        color: '#1B9757'
    },
    pending: {
        backgroundColor: '#FEFCE7',
        border: '0.2px solid #7A6F0680',
        color: '#7A6F06'
    },
    rejected: {
        backgroundColor: '#FEE7EC',
        border: '0.2px solid #A80A2D80',
        color: '#A80A2D'
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        fontWeight: 700,
        fontSize: '12px',
        padding: '4px 12px',
        textAlign: 'center',
        textTransform: 'uppercase'
    }
}));

type StatusLabelProps = {
    status: string;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
    const classes: Record<string, string> = useStyles();

    const statusClass = classNames({
        [classes.accepted]:
            status === 'Accepted' ||
            status === 'Accepted by You' ||
            status === 'Org Accepted',
        [classes.pending]:
            status === 'Active Caseload' ||
            status === 'Pending' ||
            status === 'Unenrolled',
        [classes.rejected]:
            status === 'Expired' ||
            status === 'Rejected' ||
            status === 'Rejected by You',
        [classes.statusContainer]: true
    });

    return <div className={statusClass}>{status}</div>;
};

export default StatusLabel;
