import { connect } from 'react-redux';

import Referrals from 'components/referrals';

import actions from 'redux/actions';

const mapStateToProps = (state: any) => {
    const { caseloads, isFetchingCaseloads } = state.homelesses;
    const {
        incomingReferrals,
        isFetchingIncomingReferrals,
        isFetchingOutgoingReferrals,
        outgoingReferrals,
        uniqueMemberInfo
    } = state.referrals;
    const { user } = state.user;

    return {
        caseloads,
        incomingReferrals,
        isFetchingCaseloads,
        isFetchingIncomingReferrals,
        isFetchingOutgoingReferrals,
        outgoingReferrals,
        uniqueMemberInfo,
        user
    };
};

const mapDispatchToProps = {
    acceptReferral: actions.acceptReferral,
    fetchCaseloads: actions.getCaseloads,
    fetchReferrals: actions.fetchUserReferralsRequest,
    rejectReferral: actions.rejectReferral,
    resetUserReferrals: actions.resetUserReferrals,
    removeMemberFromMyMembers: actions.removeMemberFromMyMembers,
    saveMemberToMyMembers: actions.saveMemberToMyMembers,
    setLayoutTitles: actions.setLayoutTitles
};

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
